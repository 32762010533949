body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #01011b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.App {
  position: relative;
  overflow: hidden;
  min-height: 100%;
}

.App-header-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.App-content header {
  position: relative;
  z-index: 800;
}


.App-header.large {
  height: 62px;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-left: 32px;
  padding-right: 32px;
  background: transparent 0% 0% no-repeat padding-box;
  border-bottom: 1px solid #ffffff10;
}

.App-header.large::after {
  height: 13px;
  width: 100%;
  content: " ";
  background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -13px;
  left: 0;
}

.App-header.large .App-header-links {
  display: flex;
}

.App-header.small {
  display: none;
}

.App-header-link-home {
  display: none;
}

.App-header-drawer {
  background: #04004688 0% 0% no-repeat padding-box;
  box-shadow: 8px 3px 6px #00000029;
  opacity: 1;
  -webkit-backdrop-filter: blur(27px);
          backdrop-filter: blur(27px);
  position: fixed;
  z-index: 801;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  width: 304px;
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.App-header-drawer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.App-header-link-container {
  align-self: center;
}

.App-header-drawer .App-header-link-container a {
  font-size: 14px;
  line-height: 18px;
  font-weight: normal;
  letter-spacing: 0.1px;
  color: #ffffffde;
  padding: 15px 16px;
  text-decoration: none;
  display: block;
}

/* .App-header-link-icon {
  width: 14px;
  height: 14px;
} */

.App-header-drawer .App-header-link-container a:hover,
.App-header-drawer .App-header-link-container a:focus,
.App-header-drawer .App-header-link-container a.active {
  /* background: linear-gradient(266.44deg,#28edec,#0564d7); */
  background: #303fd024;
  /* color: rgb(1,1,1); */
}

/* .App-header-link-container a.active {
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  color: rgb(1,1,1) !important;
  border-top-right-radius: 0rem;
  border-bottom-left-radius: 0rem;
  border-top-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0.5rem 1rem;
} */

.App-header-links-header {
  height: 62px;
  display: flex;
  align-items: center;
  z-index: 3;
}

.App-header-container-left {
  display: flex;
  align-items: center;
}

.App-header-top {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* z-index: 2; */
}

.App-header-menu-icon-block {
  margin-right: 12px;
  margin-left: 0px;
  display: flex;
  align-items: center;
}

.App-header-menu-icon {
  color: white;
  font-size: 1.3rem;
  cursor: pointer;
  opacity: 0.7;
  margin: 9px 10px;
}

.App-header-menu-icon:hover {
  opacity: 0.9;
}

.App-highlight-box {
  background: linear-gradient(
    45deg,
    rgba(80, 10, 245, 1) 0%,
    rgba(43, 118, 224, 1) 35%,
    rgba(7, 157, 250, 1) 77%,
    rgba(2, 207, 207, 1) 100%
  );
  border-radius: 3px;
  box-shadow: 0 0 4px 6px rgba(176, 45, 252, 0.15);
}

.App-box,
.App-card,
.App-card-primary,
.App-box-highlight,
.App-box-solid {
  position: relative;
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.App-card-bottom-placeholder {
  visibility: hidden;
}

.App-card-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1rem;
  padding-bottom: 1.2rem;
}

.App-card-bottom .App-card-divider,
.App-card-bottom-placeholder .App-card-divider {
  margin-bottom: 1.2rem;
}

.App-card-long {
  background: #000526;
  margin-bottom: 1.5484rem;
}

.App-card-long-content {
  display: flex;
  padding: 1.032rem 0;
}

.App-card-long_sub {
  width: 20%;
  padding: 0 1.032rem;
}

.App-card-long_sub__info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0.5rem;
}

.App-card-long_sub__info___title {
  font-size: 1.032rem;
  line-height: 1.3548rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-long_sub__info___subtitle {
  font-size: 0.774rem;
  line-height: 0.9677rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffffde;
}

.App-card-long-sub-left {
  display: flex;
}

.App-card-long_sub-icon {
  display: flex;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon {
  margin-left: 8px;
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.App-card-long_sub__iconlist .App-card-long_sub__iconlist___icon img:hover {
  -webkit-filter: unset;
          filter: unset;
}

.App-card-long_sub__iconlist {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.App-card-divider-vertical {
  width: 1px;
  height: 5.6774rem;
  background: #c4b9a0;
}

.App-card-long_sub:first-child {
  display: flex;
  align-items: center;
}

.App-card-long_sub__title {
  color: #c4b9a0;
  font-size: 0.903rem;
  line-height: 1.1613rem;
  font-weight: normal;
  letter-spacing: 0.25px;
  margin-bottom: 0.516rem;
}

.App-card-long_sub__subtitle {
  letter-spacing: 0.36px;
  color: white;
  font-size: 1.2903rem;
  line-height: 1.6774rem;
  font-weight: normal;
}

.App-card-title-block {
  display: flex;
  justify-content: space-between;
  padding: 1.032rem;
}

.App-card-title-info-icon {
  display: flex;
  margin-right: 8px;
}

.App-card-title-info {
  display: flex;
}

.App-card-info-title {
  font-size: 1.032rem;
  line-height: 1.3548rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-info-subtitle {
  font-size: 0.774rem;
  line-height: 0.9677rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #a9a9b0;
}

.App-card-title-iconlist {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
}

.App-card-title-iconlist___icon img {
  -webkit-filter: grayscale(1);
          filter: grayscale(1);
}

.App-card-title-iconlist___icon img:hover {
  -webkit-filter: unset;
          filter: unset;
}

.Wallet-btn {
  display: block;
  box-shadow: inset 0px 0px 10px 5px rgba(27, 30, 46, 0.2);
  background: #212957;
  /* background: #141414; */
  height: 3.3rem;
  width: 17rem;
  border: none !important;
  border-radius: 15px;
  position: relative;
  color: #fcfcfc;
  font-size: 1.3rem;
  display: grid;
  grid-template-columns: auto 1fr;
  text-align: left;
  padding-left: 1.2rem;
  padding-top: 0.7rem;
}
.Wallet-btn:hover {
  background: #1e2650;
  /* border-color: var(--dark-blue-border); */
}
.Wallet-btn:active {
  background: #1e2650;
  /* background: #5a5600; */
}

.Wallet-btn:not(:last-child) {
  margin-bottom: 0.7rem;
}

.MetaMask-btn img {
  display: inline-block;
  height: 1.5rem;
  margin-right: 1.1rem;
  margin-left: 0.1rem;
}

.CoinbaseWallet-btn img {
  display: inline-block;
  height: 1.8rem;
  margin-right: 1.1rem;
}

.WalletConnect-btn img {
  display: inline-block;
  height: 1.8rem;
  margin-right: 1.2rem;
}

button.App-connect-wallet {
  cursor: pointer;
  display: inline-block;
  border: none;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  padding: 9px 24px;
  height: 36px;
  color: #210046;
  background: #03cfcd 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

button.App-connect-wallet:hover {
  opacity: 1;
}

.App-header-user-link {
  margin-right: 24px;
}

.App-header-user-address {
  border: 1px solid #ffffff29;
  height: 36px;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  color: white;
  position: relative;
}

.App-card-divider {
  height: 1px;
  background: rgb(28 36 75);
  margin: 0.7rem -1rem;
}

.App-box-solid {
  background: linear-gradient(90deg, rgba(20, 21, 38, 1) 0%, rgba(25, 27, 47, 1) 100%);
}

.Page {
  max-width: 70rem;
  margin: auto;
  padding-top: 3rem;
}

.Page-content {
  padding: 3rem;
  padding-top: 1rem;
}

.section-title-block {
  display: flex;
  margin-bottom: 1.5rem;
  max-width: 37.677rem;
  width: 100%;
}

.page-main-content .section-title-block {
  margin-top: 4rem;
}

.section-title-icon {
  /* margin-right: 0.774rem; */
  display: flex;
  align-items: center;
}

.section-title-icon img {
  /* display: flex; */
  display: none;
}

.section-title-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.section-title-content .section-title-content__title {
  font-size: 1.5rem;
  line-height: 2rem;
  color: white;
  letter-spacing: 0px;
}

.section-title-content .section-title-content__description {
  font-size: 1.161rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #ffffffde;
  margin-top: 4px;
  max-width: 40rem;
}

.section-title-content .section-title-content__description span {
  cursor: pointer;
  color: white;
}

.Page-title-section {
  padding-left: 3rem;
  padding-right: 1rem;
  margin-top: 1rem;
  position: relative;
}

.Page-title-section:first-child {
  margin-top: 0rem;
}

.Page-title {
  font-size: 2.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  font-family: 'Etna', Consolas;
  letter-spacing:2px;
}

.Page-description {
  /* opacity: 0.7; */
  color: #b7b7bd;
  line-height: 1.4rem;
}

.Page-description span,
.Page-description a {
  text-decoration: underline;
  cursor: pointer;
  display: inline-flex;
  color: inherit;
}

.Page-description span img,
.Page-description a img {
  margin-left: 4px;
}

.App-hero {
  position: relative;
  text-align: center;
  padding-top: 4rem;
  margin-bottom: 2rem;
}

.App-hero-primary {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.App-note {
  text-align: center;
  font-weight: bold;
  font-size: 0.9rem;
  padding: 0 2rem;
}

.App-card {
  padding: 1rem 1rem 1.2rem;
  /* border: 1px solid #1e2136; */
  border-radius: 20px;
}

.App-card-title {
  font-size: 1.032rem;
  line-height: 1.355rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.App-card-title-small {
  font-size: 1.1rem;
  margin-bottom: 1rem;
}

.App-card-content {
  display: grid;
  grid-row-gap: 0.5rem;
}

.App-card-row {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 1rem;
  padding: unset;
}

.App-card-row.inner {
  padding-left: 1rem;
}
.App-card-row .icon {
  vertical-align: baseline;
  margin-left: 0.2rem;
  position: relative;
  top: 2px;
}

.Tooltip-row {
  display: flex;
  justify-content: space-between;
}

.App-card-row > div:last-child {
  text-align: right;
}

.App-card-options {
  padding: unset;
  margin: -0.4rem;
}

.App-card-option {
  margin: 0.4rem;
}

@font-face {
  font-family: "etna";
  src: local("etna"),
   url(/static/media/etna.a17cb1da.otf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "inter";
  src: local("inter"),
   url(/static/media/Inter.b559a93b.ttf) format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "inter-bold";
  src: local("inter-bold"),
   url(/static/media/Inter-Bold.9aab1ebe.ttf) format("truetype");
  font-weight: bold;
}

.App-header a.App-header-link-main,
.App-header .App-header-link-main {
  font-size: 1.6rem;
  padding: 1rem;
  margin-left: -1rem;
  display: flex;
  letter-spacing: 2px;
  margin-right: 0.7742rem;

  font-family: "etna";
  align-items: center;
}

.App-header-link-main img {
  display: inline-block;
  height: 1.4rem;
  vertical-align: middle;
}

.App-header-link-main img.small {
  display: none;
}

.App-header a {
  text-decoration: none;
  vertical-align: middle;
  display: inline-block;
}

.App-header a.active {
  pointer-events: none;
  opacity: 1;
  color: #eee;
}

.App-header.large .App-header-links a:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 31 69/var(--tw-bg-opacity));
  padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 1rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 0.5rem;
    color: white;
}

.App-header-container-left a {
  padding: 18px 17px;
  display: block;
  color: #ffffffde;
  font-size: 15px;
  line-height: 1.2rem;
  font-weight: 300;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom-left-radius: 0rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 0rem;
  margin:0 5px;
}

.App-header-container-left a.active {
  color: black;
  font-weight:normal;
  background:linear-gradient(260deg,#28b2ed,#0564d7)!important;
}

.App-header-container-left a.disabled {
  cursor: not-allowed;
  color: #ffffff61;
}

.Toastify {
  font-size: 0.9rem;
  font-family: 'Inter';
}

.Toastify__toast {
  min-height: 3rem;
  cursor: default;
}

.Toastify__toast-body {
  padding: 0 0.3rem;
  font-family: 'Inter', Consolas !important;
}

.Toastify-debug {
  margin-top: 0.3rem;
  font-size: 0.8rem;
  opacity: 0.7;
}
.Toastify-debug-button {
  border-bottom: 1px dashed;
  cursor: pointer;
}

.App-icon {
  vertical-align: middle;
  margin-bottom: 0.3rem;
}

.App-background-side-1 {
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(176, 45, 252, 0.05) 0%, rgba(8, 8, 10, 0) 100%);
}

.App-background-side-2 {
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 216, 240, 0.01) 51%, rgba(0, 216, 240, 0.03) 100%);
}

.App-background {
  z-index: 4;
  position: absolute;
  left: -100%;
  right: 0%;
  top: -20%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(80, 10, 245, 0.05) 0%, rgba(80, 10, 245, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-1 {
  z-index: 3;
  position: absolute;
  left: 0%;
  right: 0%;
  top: -50%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(102, 56, 217, 0.05) 0%, rgba(56, 33, 105, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-background-ball-2 {
  z-index: 2;
  position: absolute;
  left: -50%;
  right: -150%;
  top: -30%;
  bottom: -200%;
  pointer-events: none;
  background: radial-gradient(circle, rgba(7, 149, 250, 0.05) 0%, rgba(7, 149, 250, 0.02) 36%, rgba(8, 8, 10, 0) 100%);
}

.App-highlight {
  z-index: 0;
  position: absolute;
  left: 0%;
  right: -100%;
  top: -100%;
  bottom: 0%;
  pointer-events: none;
}

.App-title {
  text-align: center;
  font-size: 3rem;
  margin-top: 17vh;
  margin-bottom: 1rem;
  font-weight: bold;
}

.App-subtitle {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 5rem;
  opacity: 0.9;
  padding: 0 1rem;
}

.App-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.App-info-label {
  opacity: 0.7;
}

.App-cta {
  cursor: pointer;
  display: inline-block;
  padding: 1rem;
  border-radius: 3px;
  border: none;
  font-size: 14px;
}

a.App-cta,
button.App-cta {
  color: white;
  text-decoration: none;
  background: linear-gradient(260deg,#28b2ed,#0564d7);
  border-radius: 24px 0;
  position: relative;
  overflow: hidden;
  z-index:1
}

a.App-cta:hover,
button.App-cta:hover:enabled {
  background-color: linear-gradient(266.44deg,#28edec,#0564d7);
  /* background-color: #ffc444de; */
}

.App-cta.small {
  padding: 0.6rem 0.7rem;
}

a.App-cta.transparent,
button.App-cta.transparent {
  box-shadow: none;
  background: none;
  color: rgba(255, 255, 255, 0.7);
  opacity: 1;
}

a.App-cta.transparent:hover,
button.App-cta.transparent:hover:enabled {
  color: white;
  box-shadow: none;
  background: none;
}

button.App-cta.option,
a.App-cta.option {
  padding: 0.5rem 1rem;
  font-size: 0.9rem;
}

a.App-button-option,
button.App-button-option {
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  color: #ffffff;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  background: linear-gradient(260deg,#28b2ed,#0564d7);
  border-radius: 6px;
  font-weight: 700!important;
  text-decoration: none;
  text-transform: capitalize !important;
  border: 0px !important;
}
a.App-button-option:hover,
button.App-button-option:hover{
  background: linear-gradient(260deg,#28b2ed,#0564d7 60%)!important;
}

a.App-button-option:hover,
button.App-button-option:hover:enabled {
  background: linear-gradient(260deg,#28b2ed,#0564d7 60%)!important;

}

button.App-button-option:disabled {
  cursor: not-allowed;
}

.App-header-container-right {
  display: flex;
  align-items: center;
}

.App-header-user {
  display: flex;
  align-items: center;
}

.App-header-user-settings {
  display: inline-flex;
  cursor: pointer;
  border: none;
  border-radius: 1rem;
  font-size: 1rem;
  background: none;
  color: white;
  padding: 0;
  width: 24px;
  height: 24px;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.App-settings-row {
  margin-bottom: 0.5rem;
}

.App-settings .App-cta {
  margin-top: 1rem;
}

.App-slippage-tolerance-input-container {
  position: relative;
}

.App-slippage-tolerance-input {
  border: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.App-slippage-tolerance-input-percent {
  position: absolute;
  right: 0.7rem;
  width: 2rem;
  top: 1.5rem;
  bottom: 0;
  text-align: right;
}

.Network-icon {
  vertical-align: middle;
  width: 1.2rem;
  height: 1.2rem;
}

.App-header-user-netowork {
  margin-right: 24px;
}

.App-content {
  position: static !important;
  /* background: radial-gradient(ellipse at top,#14130d 10%,transparent 80%),radial-gradient(ellipse at bottom,#433d1c 10%,transparent 80%) !important; */
  background-image: url(/static/media/solid_background_3.95455cb0.png) !important;
  /* background-size: cover; */
  background-repeat: no-repeat;
}

.App-content.no-background{
  background:none!important;
}

.App-footer {
  left: 0;
  right: 0;
  position: fixed;
  bottom: 5vh;
  text-align: center;
}

a.App-social-link {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
  font-size: 1.7rem;
  color: white;
  opacity: 0.8;
}

a.App-social-link:hover {
  opacity: 0.9;
}

.App-input {
  text-align: center;
  display: inline-block;
  width: 90%;
  max-width: 30rem;
}

.App-input input {
  width: 100%;
}

.App-input-top {
  display: grid;
  grid-template-columns: auto auto;
  margin-bottom: 0.5rem;
  padding-left: 0.1rem;
  padding-right: 0.3rem;
}

.App-input-label {
  text-align: left;
  opacity: 0.9;
}

.App-input-max {
  text-align: right;
  opacity: 0.9;
  cursor: pointer;
}

.App-input-max:hover {
  opacity: 1;
}

.App-input-container {
  border: 2px solid rgba(130, 104, 31, 0.8);
  background: rgba(0, 0, 0, 0.1);
  display: grid;
  grid-template-columns: 1fr auto;
}

.App-input-unit {
  text-align: right;
  padding: 0.8rem;
  font-size: 1.3rem;
}

button.App-button {
  text-align: center;
  display: inline-block;
  font-size: 1.1rem;
  width: 90%;
  max-width: 30rem;
}

.Toastify__toast--error {
  background: #333957;
  color: white;
  border-radius: 6px;
}

.Toastify__toast--success {
  background: transparent linear-gradient(90deg, #19836c 0%, #177c66 100%) 0% 0% no-repeat padding-box;
  color: white;
  box-shadow: 0px 3px 6px #0000005c;
  border-radius: 4px;
}

.Toastify__close-button {
  align-self: flex-start;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: none;
}
.Toastify__close-button:hover {
  background: #ffffff47 0% 0% no-repeat padding-box;
}
.Toastify__toast-body {
  font: normal normal normal 14px/16px Relative;
  line-height: 1.3rem;
}

.dropdown-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.zoomIn {
  -webkit-animation-name: zoomIn;
          animation-name: zoomIn;
}

@-webkit-keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }

  to {
    opacity: 0;
  }
}

.zoomOut {
  -webkit-animation-name: zoomOut;
          animation-name: zoomOut;
}

.positive {
  color: #0ecc83;
}

.positive-background {
  background: linear-gradient(45deg, rgba(10, 115, 75, 1) 0%, rgba(12, 154, 100, 1) 100%);
}

.negative {
  color: #fa3c58;
}

.negative-background {
  background: linear-gradient(45deg, rgba(115, 30, 43, 1) 0%, rgba(174, 43, 63, 1) 100%);
}

.App-warning,
.App-warning a {
  color: #f037b5;
}

.App-warning {
  padding: 0 2rem;
}

.App-slider .rc-slider-rail {
  background: linear-gradient(90deg, rgb(30, 31, 61) 0%, rgb(40, 49, 74) 100%);
}

.App-slider .rc-slider-track {
  background: linear-gradient(90deg, rgb(46, 80, 205) 0%, rgb(45, 83, 252) 100%);
}

.App-slider .rc-slider-dot {
  border: none;
  border-radius: 1px;
  width: 2px;
  margin-left: -1px;
  background: rgb(40, 45, 74);
}

.App-slider .rc-slider-dot-active {
  background: #3d3dff;
}

.App-slider .rc-slider-handle {
  background: #303552;
  border: solid 2px rgb(45, 86, 252);
}

.App-slider .rc-slider-handle:active {
  box-shadow: 0 0 4px 6px rgba(45, 86, 252, 0.2);
}

.App-slider .rc-slider-mark-text,
.App-slider .rc-slider-mark-text-active {
  color: white;
  opacity: 0.5;
}

.App-slider .rc-slider-mark-text:hover,
.App-slider .rc-slider-mark-text-active:hover {
  opacity: 1;
}

.rc-slider-tooltip {
  z-index: 5;
}

.rc-slider-tooltip-inner {
  box-shadow: none;
  padding: 0.3rem 0.5rem;
  background: #303552;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #303052;
}

.header-connect-btn {
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border-radius: 24px 0px;
}

.header-connect-btn:hover {
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border-radius: 24px 0px;
}

.header-connect-btn:focus {
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border-radius: 24px 0px;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.header-connect-btn:active {
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border-radius: 24px 0px;
}

.short-connect-btn {
  display: none !important;
}

.selector-backdrop {
  opacity: 1;
  height: 100vh;
  z-index: 2;
  -webkit-backdrop-filter: blur(17px);
          backdrop-filter: blur(17px);
  position: fixed;
  top: 0;
  width: 100%;
}

@media (max-width: 1033px) {
  .long-connect-btn {
    display: none !important;
  }
  .App-header-user-address {
    padding: 0 0px;
  }

  .short-connect-btn {
    display: flex !important;
  }

  .App-header-user-link {
    margin-right: 16px;
  }

  .App-header-user-netowork {
    margin-right: 16px;
  }

  button.App-connect-wallet {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }
  .App-header-link-home {
    display: block;
  }
  .App-cta.small {
    padding: 0.6rem 0.5rem;
  }
  .App-subtitle {
    margin-bottom: 3rem;
  }

  .App-header-user {
    padding-bottom: 0;
  }

  .App-header-container-right {
    display: flex;
    align-items: center;
  }

  .App-header-link-desktop {
    display: none;
  }

  .App-header {
    grid-template-columns: 1fr auto auto auto auto;
  }

  .App-header-link-main img {
    height: 1.355rem;
  }

  .App-header.large {
    display: none;
  }

  .App-header.small {
    height: 62px;
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-left: 16px;
    padding-right: 16px;
    background: transparent 0% 0% no-repeat padding-box;
    border-bottom: 1px solid #ffffff10;
  }

  .App-header.small::after {
    height: 13px;
    width: 100%;
    content: " ";
    background: transparent linear-gradient(180deg, #00000030 0%, #00000000 100%) 0% 0% no-repeat padding-box;
    position: absolute;
    bottom: -13px;
    left: 0;
  }

  .Page-title-section {
    margin-top: 2rem;
  }

  a.App-header-user-account {
    margin-right: 0;
  }

  .App-card-long-content.card-list {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 1000px) {
  .App-card-long-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 1rem;
  }

  .App-card-divider-vertical {
    display: none;
  }

  .App-card-long_sub {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .App-card-long-content {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 600px) {
  .App-card-long-content.card-list {
    grid-template-columns: 1fr;
  }

  .App-card-long-content {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 600px) {
  .App-header-link-main img.big {
    display: none;
  }

  .App-header-link-main img.small {
    display: inline-block;
  }
}

@media (max-width: 500px) {
  .header-connect-btn {
    margin-right: 4px;
  }
}

@media (max-width: 450px) {
  .App-header-user-link {
    margin-right: 6px;
  }

  .App-header-user-netowork {
    margin-right: 6px;
  }

  .App-header-menu-icon-block {
    margin-left: 0;
    margin-right: 4px;
  }

  .App-header-menu-icon {
    margin: 4px 5px;
  }

  .App-header.small {
    padding-left: 6px;
    padding-right: 6px;
  }

  .Home-faqs-content {
    padding-left: 1rem;
  }

  .header-connect-btn,
  .header-connect-btn:active {
    padding-left: 5px;
    padding-right: 5px;
  }

  .header-connect-btn:focus {
    padding-left: 4px;
    padding-right: 4px;
  }

  .App-header .App-header-link-main {
    padding: 0.1rem !important;
    margin-left: 0rem !important;
  }
}

.walletconnect-modal__mobile__toggle a {
  color: #000;
}
.walletconnect-modal__base {
  max-height: 100%;
}
.walletconnect-qrcode__image {
  max-height: 50vh;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .App-header-drawer {
    background: #0e0f1f;
  }
}

.spin {
  -webkit-animation: spin-animation infinite 1.2s linear;
          animation: spin-animation infinite 1.2s linear;
}

@-webkit-keyframes spin-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin-animation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.AppOrder-ball-container {
  position: relative;
  z-index: 2;
}

.AppOrder-ball-icon {
  text-align: center;
  display: block;
  font-size: 1.5rem;
  opacity: 0.7;
}

.AppOrder-ball {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 2.3rem;
  height: 2.3rem;
  left: 50%;
  margin-left: -1.15rem;
  top: -1.4rem;
  border-radius: 2rem;
  -webkit-user-select: none;
          user-select: none;
  background: rgb(59 130 245);
  cursor: pointer;
  opacity: 0.85;
}
.AppOrder-ball:hover {
  opacity: 1;
}

@font-face {
  font-family: 'Relative Bold';
  font-style: bold;
  font-weight: 500;
  src: url(/static/media/relative-bold-pro.5c45291e.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/relative-bold-pro.5c45291e.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/relative-bold-pro.d0539900.woff2) format('woff2'), 
       url(/static/media/relative-bold-pro.795575f5.woff) format('woff'), 
       url(/static/media/relative-bold-pro.419c53fd.ttf) format('truetype'); /* Safari, Android, iOS */
}

@font-face {
  font-family: 'RelativeMono';
  font-style: bold;
  font-weight: 400;
  src: url(/static/media/relative-mono-10-pitch-pro.ea8681a8.eot); /* IE9 Compat Modes */
  src: local(''),
       url(/static/media/relative-mono-10-pitch-pro.ea8681a8.eot?#iefix) format('embedded-opentype'), 
       url(/static/media/relative-mono-10-pitch-pro.a9e415a8.woff2) format('woff2'), 
       url(/static/media/relative-mono-10-pitch-pro.4341f7db.woff) format('woff'), 
       url(/static/media/relative-mono-10-pitch-pro.a8025a5b.ttf) format('truetype'); /* Safari, Android, iOS */
}

.Footer-wrapper {
  text-align: center;
  padding-top: 1.581rem;
  background: linear-gradient(to bottom,#01021a,#01021a,#00010c);
  width: 100vw;
  position: relative;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  left: 50%;
  height: 251px;
  bottom: 0;
}

@media (min-width: 364px) {
  .Footer-logo {
    margin-bottom: 1.548rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
 

@media (min-width: 1024px) {
  .poweredbyiconsize{
    width: 200px;
  }
}
@media (max-width: 1024px) {
  .poweredbyiconsize{
    width: 163px;
  }
}
@media (max-width: 350px) {
  .poweredbyiconsize{
    width: 140px;
  }
}




.Footer-logo img {
  height: 2.72rem;
}

@media (max-width: 364px) {
  .smallscreensize{
    margin-bottom: 1.148rem!important;
  }
}
.Footer-social-link-block {
  max-width: 71.613rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 1.548rem;
}


.Footer-link {
  color: #fff;
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: normal;
  text-decoration: none;
}
.Footer-link:hover {
  color: white;
}

.Footer-social-link-block .App-social-link {
  margin: 0 2.064rem;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
}

.Footer-social-link-block .App-social-link:hover img {
  -webkit-filter: brightness(0) invert(1);
          filter: brightness(0) invert(1);
}

.Footer-copyright {
  padding: 1.1rem;
}

.Footer-copyright__text {
  font-family: Circular Std;
  font-size: 0.8387rem;
  line-height: 0.8387rem;
  letter-spacing: -0.41px;
  color: #c4b9a0;
  display: block;
}
.Footer-links {
  padding-bottom: 2.5806rem;
}

@media (max-width: 900px) {
  .Footer-social-link-block .App-social-link {
    margin: 0 0.534rem;
  }
}
.ahreftextcolorfooter{
  color: white;
  font-weight:300;
  text-decoration:none;
}
.ahreftextcolorfooter:hover{
  color: rgb(59 130 245);
}

.input-form {
  max-width: 25rem;
}

.input-row {
  margin-bottom: 1rem;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
}

.text-input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  color: white;
  font-family: 'Inter', Consolas;
  letter-spacing: 0.5px;
  font-size: 15.5px;
  background-color: #01011b;
}

* {
  box-sizing: border-box;
  font-family: 'Inter', Consolas;
  font-weight:300;
  transition: all 0.1s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html{
  overflow: auto;
}
body{
  overflow-y: scroll
}

h1,h2,h3,h4,h5,h6{
  line-height: normal!important;
    font-family: 'etna',sans-serif!important;
    font-weight: 500!important;
    letter-spacing: 1.5px;
}

p{
  font-family: Inter,sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255/var(--tw-text-opacity));
}

/* Scrollbar Styling */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  /* background-color: #ebebeb; */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgb(50, 53, 92);
}

:root {
  --dark-blue: #433c00;
  --dark-blue-bg: #16182e;
  --dark-blue-accent: #c4b9a0;
  --dark-blue-hover: #808aff14;
  --dark-blue-border: #ffffff29;
  --dark-blue-active: #818cef33;
  --border-radius-sm: 4px;
}

a {
  color: white;
}
a:hover{
  color: black;
}

.Card-title {
  font-size: 2rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input {
  font-size: 1.3rem;
  color: white;
  outline: none;
  background: none;
  border: none;
  padding: 0.8rem;
  font-family: 'Inter', Consolas;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

button {
  outline: none;
  cursor: pointer;
  font-family: 'Inter', Consolas;
}

button:disabled,
button.App-cta:disabled {
  cursor: not-allowed;
  opacity: 0.9;
  box-shadow: none;
}

table {
  margin: 0;
  padding: 0;
}

tr {
  margin: 0;
  padding: 0;
}

td {
  margin: 0;
  padding: 0;
  padding-right: 0.3rem;
}

.border {
  border: 1px solid rgba(163, 165, 196, 0.2);
}

.align-right {
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.muted {
  opacity: 0.7;
}

.strong {
  font-weight: bold;
}

.clickable {
  cursor: pointer;
}

.underline {
  text-decoration: underline;
}

.text-none {
  text-transform: none;
}

.margin-bottom {
  margin-bottom: 0.3rem;
}

.transition-arrow {
  vertical-align: middle;
  margin-top: -0.2rem;
}

.inline-block {
  display: inline-block;
}

.label,
.label a {
  color: rgba(255, 255, 255, 0.7);
}

ul {
  padding-left: 1.5rem;
  margin-bottom: 0;
}

ul li {
  padding-bottom: 1rem;
}

a.plain {
  text-decoration: none;
}
a.plain:hover {
  color: rgb(59 130 245);
}

.checkbox:checked:before {
  background-color: green;
}

.default-btn {

  color: #ffffff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  /* background-color: #ffc544;
  border-radius: 30px; */
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border-radius: 24px 0px;
}
.default-btn2 {

  display: inline-flex !important;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  color: #ffffff;
  padding: 6px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  /* transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  /* background-color: #ffc544;
  border-radius: 30px; */
  background: linear-gradient(260deg,#28b2ed,#0564d7)!important;
  border-radius: 24px 0px;
  min-height: 48px;
  text-decoration: none;
}
.default-btnhome {
  display: inline-flex !important;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;

  color: #ffffff;
  padding: 8px 16px;
  font-size: 0.875rem;
  min-width: 64px !important;
  box-shadow: none;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  background: linear-gradient(260deg,#28b2ed,#0564d7);
  border-radius: 6px;

  font-family: 'Inter', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  /* background-color: #ffc544;
  border-radius: 30px; */
  /* min-height: 48px; */
  background: linear-gradient(260deg,#28b2ed,#0564d7);
  border-radius: 24px 0px;
  text-decoration: none;
}

.default-btn:hover {
  /* background-color: #ffc444de; */
  background: linear-gradient(260deg,#28b2ed,#0564d7 60%)!important;
  
  color: #fcfcfc !important;
}
.default-btn2:hover {
  /* background-color: #ffc444de; */
  color: #fcfcfc !important;
}
.default-btnhome:hover {
  /* background-color: #ffc444de; */
  background: linear-gradient(260deg,#28b2ed,#0564d7 60%)!important;

  color: #fcfcfc !important;
}
.availablebtnbg{
  background: rgb(59 130 245);
 
}
.availablebtnbg:hover{
  background: rgb(59 130 245);
 
}

.default-btn:active {
  /* background: #ffc851; */
  background: linear-gradient(266.44deg,#28edec,#0564d7);
  border: none;
  padding-left: 16px;
  padding-right: 16px;
  box-sizing: border-box;
  overflow: hidden;
 
}

.default-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: ripple 600ms linear;
          animation: ripple 600ms linear;
}

.default-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

.default-container {
  max-width: 1264px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 0 auto;
  margin-top: 50px;
}

.secondary-btn {
  background: #03d1cf;
  border-radius: 4px;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Inter', Consolas;
  font-weight: normal;
  letter-spacing: 0px;
  color: black;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px!important; */
  text-decoration: none;
  box-sizing: border-box;
  position: relative;
  min-height: 36px;
  border: none;
  display: inline-flex !important;
  align-items: center;
  text-align: center;
}

.secondary-btn:hover {
  background: #3fdad9;
}

.secondary-btn:focus {
  background: #54dedd;
  border: 1px solid #1cb5b4fc;
  box-sizing: border-box;
  padding-left: 15px;
  padding-right: 15px;
}

.secondary-btn:active {
  background: #54dedd;
  border: none;
  box-sizing: border-box;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  /* padding: 9px 16px; */
}

.secondary-btn:active::after {
  opacity: 0.239;
  content: " ";
  width: 52px;
  height: 52px;
  position: absolute;
  left: calc(50% - 26px);
  top: -8px;
  background: white;
  border-radius: 50%;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-animation: ripple 500ms linear;
          animation: ripple 500ms linear;
}

.secondary-btn:disabled {
  background: #2d2e3f 0% 0% no-repeat padding-box;
  color: #ffffff61;
}

@-webkit-keyframes ripple {
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0.4;
  }
}

@keyframes ripple {
  to {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0.4;
  }
}

button.btn-link {
  margin-top: 1rem;
  background: none;
  color: white;
  border: none;
  padding: 0;
  font-size: 1rem;
  display: block;
  text-align: left;
}

.logout-icon {
  vertical-align: middle;
  font-size: 1.1rem;
  margin-top: -0.2rem;
  margin-right: 0.35rem;
  margin-left: -0.3rem;
  opacity: 0.8;
}

button.btn-link:hover {
  text-decoration: underline;
}

button.btn-link:hover .logout-icon {
  opacity: 1;
}

.page-container {
  padding-top: 4rem;
}

.mt-0 {
  margin-top: 0 !important;
}

.page-layout {
  min-height: calc(100vh - 62px);
  display: flex;
  flex-direction: column;
  /* padding-bottom: calc(3rem + 200px); */
}

.d-flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.w-100 {
  width: 100%;
}

.default-cursor.App-cta {
  cursor: default;
}

/* NFTS NEW */

.header-dropdown {
  display: flex!important;
  align-items: center!important;
  position: relative!important;
  cursor: pointer;
}

.dropbtn {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #a0a1c4;
  border: none;
}

.header-dropdown-content {
  display: none;
  position: absolute;
  top: 35px;
  z-index: 1;
  min-width: 170px;
  background: #000526;
  /* box-shadow: 0 4px 12px #262200; */
  border-radius: 12px;
  padding: 12px;
}

.header-dropdown:hover .header-dropdown-content {
  display: block;
}

.header-dropdown-content a {
  padding: 8px!important;
}

.recharts-wrapper{
  min-width: 210px;
}

.Footer{
  margin-top: auto;
}
.menu-items:focus-visible {
  border: 1px solid #262638;
}
.address-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.user-address {
  margin-left: 10px;
  margin-right: 10px;
}

.App-header-user-address:hover {
  background: #080b27;
}

.menu-items {
  position: absolute;
  right: 0;
  top: 43px;
  min-width: 10rem;
  width: 100%;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  border-radius: 4px;
  background: #080b27;
  border: 1px solid #080b27;
  list-style: none;
  cursor: pointer;
  outline: none;
  z-index: 1000;
}
.menu-item {
  display: flex !important;
  align-items: center;
  font-size: 1rem;
  color: #fff;
  padding-bottom: 15px;
  font-size: 14px;
  padding: 8.5px 8px;
  /* border: 1px solid transparent; */
  border-radius: 4px;
}
.menu-item:hover {
  background: #080b27 !important;
  /* border: 1px solid #4275a8; */
  border-radius: 4 px;
  opacity: 1;
  color: #eee;
}
.menu-item > p {
  margin: 0px;
  padding-left: 10px;
}
.menu-item > a {
  display: inline-flex;
}

@media screen and (max-width: 370px) {
  .user-address {
    display: none;
  }
  .address-btn {
    display: flex;
    width: 60px;
    justify-content: space-between;
  }
}

/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.blue-waves {
    height: 500px;
    overflow: hidden;
    display: flex;
    font-family: 'Anton', sans-serif;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 100px;
    z-index: 0;
    width: 100%;
    zoom: 200%;
    opacity:0.7;
}
.sea {
    display: flex;
    -webkit-filter: drop-shadow(0 -7px 10px #2af4eb) blur(3px) contrast(5);
            filter: drop-shadow(0 -7px 10px #2af4eb) blur(3px) contrast(5);
}
.wave_graphic {
    width: 10px;
    height: 30px;
    background: linear-gradient(180deg, rgba(150, 220, 255, 0.5), rgba(150, 220, 255, 0.2));
}
.wave_fade {
    -webkit-animation: fade 1400ms ease-in-out infinite alternate;
            animation: fade 1400ms ease-in-out infinite alternate;
}
.wave_skew {
    -webkit-animation: skew 4000ms ease-in-out infinite alternate;
            animation: skew 4000ms ease-in-out infinite alternate;
}
.wave_translate {
    -webkit-animation: translate 4000ms ease-in-out infinite alternate;
            animation: translate 4000ms ease-in-out infinite alternate;
}
.wave:nth-child(1) .wave_fade {
    -webkit-animation-delay: -20ms;
            animation-delay: -20ms;
}
.wave:nth-child(1) .wave_skew {
    -webkit-animation-delay: -20ms;
            animation-delay: -20ms;
}
.wave:nth-child(1) .wave_translate {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(2) .wave_fade {
    -webkit-animation-delay: -40ms;
            animation-delay: -40ms;
}
.wave:nth-child(2) .wave_skew {
    -webkit-animation-delay: -40ms;
            animation-delay: -40ms;
}
.wave:nth-child(2) .wave_translate {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(3) .wave_fade {
    -webkit-animation-delay: -60ms;
            animation-delay: -60ms;
}
.wave:nth-child(3) .wave_skew {
    -webkit-animation-delay: -60ms;
            animation-delay: -60ms;
}
.wave:nth-child(3) .wave_translate {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(4) .wave_fade {
    -webkit-animation-delay: -80ms;
            animation-delay: -80ms;
}
.wave:nth-child(4) .wave_skew {
    -webkit-animation-delay: -80ms;
            animation-delay: -80ms;
}
.wave:nth-child(4) .wave_translate {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(5) .wave_fade {
    -webkit-animation-delay: -100ms;
            animation-delay: -100ms;
}
.wave:nth-child(5) .wave_skew {
    -webkit-animation-delay: -100ms;
            animation-delay: -100ms;
}
.wave:nth-child(5) .wave_translate {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(6) .wave_fade {
    -webkit-animation-delay: -120ms;
            animation-delay: -120ms;
}
.wave:nth-child(6) .wave_skew {
    -webkit-animation-delay: -120ms;
            animation-delay: -120ms;
}
.wave:nth-child(6) .wave_translate {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(7) .wave_fade {
    -webkit-animation-delay: -140ms;
            animation-delay: -140ms;
}
.wave:nth-child(7) .wave_skew {
    -webkit-animation-delay: -140ms;
            animation-delay: -140ms;
}
.wave:nth-child(7) .wave_translate {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(8) .wave_fade {
    -webkit-animation-delay: -160ms;
            animation-delay: -160ms;
}
.wave:nth-child(8) .wave_skew {
    -webkit-animation-delay: -160ms;
            animation-delay: -160ms;
}
.wave:nth-child(8) .wave_translate {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(9) .wave_fade {
    -webkit-animation-delay: -180ms;
            animation-delay: -180ms;
}
.wave:nth-child(9) .wave_skew {
    -webkit-animation-delay: -180ms;
            animation-delay: -180ms;
}
.wave:nth-child(9) .wave_translate {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(10) .wave_fade {
    -webkit-animation-delay: -200ms;
            animation-delay: -200ms;
}
.wave:nth-child(10) .wave_skew {
    -webkit-animation-delay: -200ms;
            animation-delay: -200ms;
}
.wave:nth-child(10) .wave_translate {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(11) .wave_fade {
    -webkit-animation-delay: -220ms;
            animation-delay: -220ms;
}
.wave:nth-child(11) .wave_skew {
    -webkit-animation-delay: -220ms;
            animation-delay: -220ms;
}
.wave:nth-child(11) .wave_translate {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(12) .wave_fade {
    -webkit-animation-delay: -240ms;
            animation-delay: -240ms;
}
.wave:nth-child(12) .wave_skew {
    -webkit-animation-delay: -240ms;
            animation-delay: -240ms;
}
.wave:nth-child(12) .wave_translate {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(13) .wave_fade {
    -webkit-animation-delay: -260ms;
            animation-delay: -260ms;
}
.wave:nth-child(13) .wave_skew {
    -webkit-animation-delay: -260ms;
            animation-delay: -260ms;
}
.wave:nth-child(13) .wave_translate {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(14) .wave_fade {
    -webkit-animation-delay: -280ms;
            animation-delay: -280ms;
}
.wave:nth-child(14) .wave_skew {
    -webkit-animation-delay: -280ms;
            animation-delay: -280ms;
}
.wave:nth-child(14) .wave_translate {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(15) .wave_fade {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
}
.wave:nth-child(15) .wave_skew {
    -webkit-animation-delay: -300ms;
            animation-delay: -300ms;
}
.wave:nth-child(15) .wave_translate {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(16) .wave_fade {
    -webkit-animation-delay: -320ms;
            animation-delay: -320ms;
}
.wave:nth-child(16) .wave_skew {
    -webkit-animation-delay: -320ms;
            animation-delay: -320ms;
}
.wave:nth-child(16) .wave_translate {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(17) .wave_fade {
    -webkit-animation-delay: -340ms;
            animation-delay: -340ms;
}
.wave:nth-child(17) .wave_skew {
    -webkit-animation-delay: -340ms;
            animation-delay: -340ms;
}
.wave:nth-child(17) .wave_translate {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(18) .wave_fade {
    -webkit-animation-delay: -360ms;
            animation-delay: -360ms;
}
.wave:nth-child(18) .wave_skew {
    -webkit-animation-delay: -360ms;
            animation-delay: -360ms;
}
.wave:nth-child(18) .wave_translate {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(19) .wave_fade {
    -webkit-animation-delay: -380ms;
            animation-delay: -380ms;
}
.wave:nth-child(19) .wave_skew {
    -webkit-animation-delay: -380ms;
            animation-delay: -380ms;
}
.wave:nth-child(19) .wave_translate {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(20) .wave_fade {
    -webkit-animation-delay: -400ms;
            animation-delay: -400ms;
}
.wave:nth-child(20) .wave_skew {
    -webkit-animation-delay: -400ms;
            animation-delay: -400ms;
}
.wave:nth-child(20) .wave_translate {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(21) .wave_fade {
    -webkit-animation-delay: -420ms;
            animation-delay: -420ms;
}
.wave:nth-child(21) .wave_skew {
    -webkit-animation-delay: -420ms;
            animation-delay: -420ms;
}
.wave:nth-child(21) .wave_translate {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(22) .wave_fade {
    -webkit-animation-delay: -440ms;
            animation-delay: -440ms;
}
.wave:nth-child(22) .wave_skew {
    -webkit-animation-delay: -440ms;
            animation-delay: -440ms;
}
.wave:nth-child(22) .wave_translate {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(23) .wave_fade {
    -webkit-animation-delay: -460ms;
            animation-delay: -460ms;
}
.wave:nth-child(23) .wave_skew {
    -webkit-animation-delay: -460ms;
            animation-delay: -460ms;
}
.wave:nth-child(23) .wave_translate {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(24) .wave_fade {
    -webkit-animation-delay: -480ms;
            animation-delay: -480ms;
}
.wave:nth-child(24) .wave_skew {
    -webkit-animation-delay: -480ms;
            animation-delay: -480ms;
}
.wave:nth-child(24) .wave_translate {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(25) .wave_fade {
    -webkit-animation-delay: -500ms;
            animation-delay: -500ms;
}
.wave:nth-child(25) .wave_skew {
    -webkit-animation-delay: -500ms;
            animation-delay: -500ms;
}
.wave:nth-child(25) .wave_translate {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(26) .wave_fade {
    -webkit-animation-delay: -520ms;
            animation-delay: -520ms;
}
.wave:nth-child(26) .wave_skew {
    -webkit-animation-delay: -520ms;
            animation-delay: -520ms;
}
.wave:nth-child(26) .wave_translate {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(27) .wave_fade {
    -webkit-animation-delay: -540ms;
            animation-delay: -540ms;
}
.wave:nth-child(27) .wave_skew {
    -webkit-animation-delay: -540ms;
            animation-delay: -540ms;
}
.wave:nth-child(27) .wave_translate {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(28) .wave_fade {
    -webkit-animation-delay: -560ms;
            animation-delay: -560ms;
}
.wave:nth-child(28) .wave_skew {
    -webkit-animation-delay: -560ms;
            animation-delay: -560ms;
}
.wave:nth-child(28) .wave_translate {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(29) .wave_fade {
    -webkit-animation-delay: -580ms;
            animation-delay: -580ms;
}
.wave:nth-child(29) .wave_skew {
    -webkit-animation-delay: -580ms;
            animation-delay: -580ms;
}
.wave:nth-child(29) .wave_translate {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(30) .wave_fade {
    -webkit-animation-delay: -600ms;
            animation-delay: -600ms;
}
.wave:nth-child(30) .wave_skew {
    -webkit-animation-delay: -600ms;
            animation-delay: -600ms;
}
.wave:nth-child(30) .wave_translate {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(31) .wave_fade {
    -webkit-animation-delay: -620ms;
            animation-delay: -620ms;
}
.wave:nth-child(31) .wave_skew {
    -webkit-animation-delay: -620ms;
            animation-delay: -620ms;
}
.wave:nth-child(31) .wave_translate {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(32) .wave_fade {
    -webkit-animation-delay: -640ms;
            animation-delay: -640ms;
}
.wave:nth-child(32) .wave_skew {
    -webkit-animation-delay: -640ms;
            animation-delay: -640ms;
}
.wave:nth-child(32) .wave_translate {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(33) .wave_fade {
    -webkit-animation-delay: -660ms;
            animation-delay: -660ms;
}
.wave:nth-child(33) .wave_skew {
    -webkit-animation-delay: -660ms;
            animation-delay: -660ms;
}
.wave:nth-child(33) .wave_translate {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(34) .wave_fade {
    -webkit-animation-delay: -680ms;
            animation-delay: -680ms;
}
.wave:nth-child(34) .wave_skew {
    -webkit-animation-delay: -680ms;
            animation-delay: -680ms;
}
.wave:nth-child(34) .wave_translate {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(35) .wave_fade {
    -webkit-animation-delay: -700ms;
            animation-delay: -700ms;
}
.wave:nth-child(35) .wave_skew {
    -webkit-animation-delay: -700ms;
            animation-delay: -700ms;
}
.wave:nth-child(35) .wave_translate {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(36) .wave_fade {
    -webkit-animation-delay: -720ms;
            animation-delay: -720ms;
}
.wave:nth-child(36) .wave_skew {
    -webkit-animation-delay: -720ms;
            animation-delay: -720ms;
}
.wave:nth-child(36) .wave_translate {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(37) .wave_fade {
    -webkit-animation-delay: -740ms;
            animation-delay: -740ms;
}
.wave:nth-child(37) .wave_skew {
    -webkit-animation-delay: -740ms;
            animation-delay: -740ms;
}
.wave:nth-child(37) .wave_translate {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(38) .wave_fade {
    -webkit-animation-delay: -760ms;
            animation-delay: -760ms;
}
.wave:nth-child(38) .wave_skew {
    -webkit-animation-delay: -760ms;
            animation-delay: -760ms;
}
.wave:nth-child(38) .wave_translate {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(39) .wave_fade {
    -webkit-animation-delay: -780ms;
            animation-delay: -780ms;
}
.wave:nth-child(39) .wave_skew {
    -webkit-animation-delay: -780ms;
            animation-delay: -780ms;
}
.wave:nth-child(39) .wave_translate {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(40) .wave_fade {
    -webkit-animation-delay: -800ms;
            animation-delay: -800ms;
}
.wave:nth-child(40) .wave_skew {
    -webkit-animation-delay: -800ms;
            animation-delay: -800ms;
}
.wave:nth-child(40) .wave_translate {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(41) .wave_fade {
    -webkit-animation-delay: -820ms;
            animation-delay: -820ms;
}
.wave:nth-child(41) .wave_skew {
    -webkit-animation-delay: -820ms;
            animation-delay: -820ms;
}
.wave:nth-child(41) .wave_translate {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(42) .wave_fade {
    -webkit-animation-delay: -840ms;
            animation-delay: -840ms;
}
.wave:nth-child(42) .wave_skew {
    -webkit-animation-delay: -840ms;
            animation-delay: -840ms;
}
.wave:nth-child(42) .wave_translate {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(43) .wave_fade {
    -webkit-animation-delay: -860ms;
            animation-delay: -860ms;
}
.wave:nth-child(43) .wave_skew {
    -webkit-animation-delay: -860ms;
            animation-delay: -860ms;
}
.wave:nth-child(43) .wave_translate {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(44) .wave_fade {
    -webkit-animation-delay: -880ms;
            animation-delay: -880ms;
}
.wave:nth-child(44) .wave_skew {
    -webkit-animation-delay: -880ms;
            animation-delay: -880ms;
}
.wave:nth-child(44) .wave_translate {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(45) .wave_fade {
    -webkit-animation-delay: -900ms;
            animation-delay: -900ms;
}
.wave:nth-child(45) .wave_skew {
    -webkit-animation-delay: -900ms;
            animation-delay: -900ms;
}
.wave:nth-child(45) .wave_translate {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(46) .wave_fade {
    -webkit-animation-delay: -920ms;
            animation-delay: -920ms;
}
.wave:nth-child(46) .wave_skew {
    -webkit-animation-delay: -920ms;
            animation-delay: -920ms;
}
.wave:nth-child(46) .wave_translate {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(47) .wave_fade {
    -webkit-animation-delay: -940ms;
            animation-delay: -940ms;
}
.wave:nth-child(47) .wave_skew {
    -webkit-animation-delay: -940ms;
            animation-delay: -940ms;
}
.wave:nth-child(47) .wave_translate {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(48) .wave_fade {
    -webkit-animation-delay: -960ms;
            animation-delay: -960ms;
}
.wave:nth-child(48) .wave_skew {
    -webkit-animation-delay: -960ms;
            animation-delay: -960ms;
}
.wave:nth-child(48) .wave_translate {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(49) .wave_fade {
    -webkit-animation-delay: -980ms;
            animation-delay: -980ms;
}
.wave:nth-child(49) .wave_skew {
    -webkit-animation-delay: -980ms;
            animation-delay: -980ms;
}
.wave:nth-child(49) .wave_translate {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(50) .wave_fade {
    -webkit-animation-delay: -1000ms;
            animation-delay: -1000ms;
}
.wave:nth-child(50) .wave_skew {
    -webkit-animation-delay: -1000ms;
            animation-delay: -1000ms;
}
.wave:nth-child(50) .wave_translate {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(51) .wave_fade {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(51) .wave_skew {
    -webkit-animation-delay: -1020ms;
            animation-delay: -1020ms;
}
.wave:nth-child(51) .wave_translate {
    -webkit-animation-delay: -2020ms;
            animation-delay: -2020ms;
}
.wave:nth-child(52) .wave_fade {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(52) .wave_skew {
    -webkit-animation-delay: -1040ms;
            animation-delay: -1040ms;
}
.wave:nth-child(52) .wave_translate {
    -webkit-animation-delay: -2040ms;
            animation-delay: -2040ms;
}
.wave:nth-child(53) .wave_fade {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(53) .wave_skew {
    -webkit-animation-delay: -1060ms;
            animation-delay: -1060ms;
}
.wave:nth-child(53) .wave_translate {
    -webkit-animation-delay: -2060ms;
            animation-delay: -2060ms;
}
.wave:nth-child(54) .wave_fade {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(54) .wave_skew {
    -webkit-animation-delay: -1080ms;
            animation-delay: -1080ms;
}
.wave:nth-child(54) .wave_translate {
    -webkit-animation-delay: -2080ms;
            animation-delay: -2080ms;
}
.wave:nth-child(55) .wave_fade {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(55) .wave_skew {
    -webkit-animation-delay: -1100ms;
            animation-delay: -1100ms;
}
.wave:nth-child(55) .wave_translate {
    -webkit-animation-delay: -2100ms;
            animation-delay: -2100ms;
}
.wave:nth-child(56) .wave_fade {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(56) .wave_skew {
    -webkit-animation-delay: -1120ms;
            animation-delay: -1120ms;
}
.wave:nth-child(56) .wave_translate {
    -webkit-animation-delay: -2120ms;
            animation-delay: -2120ms;
}
.wave:nth-child(57) .wave_fade {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(57) .wave_skew {
    -webkit-animation-delay: -1140ms;
            animation-delay: -1140ms;
}
.wave:nth-child(57) .wave_translate {
    -webkit-animation-delay: -2140ms;
            animation-delay: -2140ms;
}
.wave:nth-child(58) .wave_fade {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(58) .wave_skew {
    -webkit-animation-delay: -1160ms;
            animation-delay: -1160ms;
}
.wave:nth-child(58) .wave_translate {
    -webkit-animation-delay: -2160ms;
            animation-delay: -2160ms;
}
.wave:nth-child(59) .wave_fade {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(59) .wave_skew {
    -webkit-animation-delay: -1180ms;
            animation-delay: -1180ms;
}
.wave:nth-child(59) .wave_translate {
    -webkit-animation-delay: -2180ms;
            animation-delay: -2180ms;
}
.wave:nth-child(60) .wave_fade {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(60) .wave_skew {
    -webkit-animation-delay: -1200ms;
            animation-delay: -1200ms;
}
.wave:nth-child(60) .wave_translate {
    -webkit-animation-delay: -2200ms;
            animation-delay: -2200ms;
}
.wave:nth-child(61) .wave_fade {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(61) .wave_skew {
    -webkit-animation-delay: -1220ms;
            animation-delay: -1220ms;
}
.wave:nth-child(61) .wave_translate {
    -webkit-animation-delay: -2220ms;
            animation-delay: -2220ms;
}
.wave:nth-child(62) .wave_fade {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(62) .wave_skew {
    -webkit-animation-delay: -1240ms;
            animation-delay: -1240ms;
}
.wave:nth-child(62) .wave_translate {
    -webkit-animation-delay: -2240ms;
            animation-delay: -2240ms;
}
.wave:nth-child(63) .wave_fade {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(63) .wave_skew {
    -webkit-animation-delay: -1260ms;
            animation-delay: -1260ms;
}
.wave:nth-child(63) .wave_translate {
    -webkit-animation-delay: -2260ms;
            animation-delay: -2260ms;
}
.wave:nth-child(64) .wave_fade {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(64) .wave_skew {
    -webkit-animation-delay: -1280ms;
            animation-delay: -1280ms;
}
.wave:nth-child(64) .wave_translate {
    -webkit-animation-delay: -2280ms;
            animation-delay: -2280ms;
}
.wave:nth-child(65) .wave_fade {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(65) .wave_skew {
    -webkit-animation-delay: -1300ms;
            animation-delay: -1300ms;
}
.wave:nth-child(65) .wave_translate {
    -webkit-animation-delay: -2300ms;
            animation-delay: -2300ms;
}
.wave:nth-child(66) .wave_fade {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(66) .wave_skew {
    -webkit-animation-delay: -1320ms;
            animation-delay: -1320ms;
}
.wave:nth-child(66) .wave_translate {
    -webkit-animation-delay: -2320ms;
            animation-delay: -2320ms;
}
.wave:nth-child(67) .wave_fade {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(67) .wave_skew {
    -webkit-animation-delay: -1340ms;
            animation-delay: -1340ms;
}
.wave:nth-child(67) .wave_translate {
    -webkit-animation-delay: -2340ms;
            animation-delay: -2340ms;
}
.wave:nth-child(68) .wave_fade {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(68) .wave_skew {
    -webkit-animation-delay: -1360ms;
            animation-delay: -1360ms;
}
.wave:nth-child(68) .wave_translate {
    -webkit-animation-delay: -2360ms;
            animation-delay: -2360ms;
}
.wave:nth-child(69) .wave_fade {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(69) .wave_skew {
    -webkit-animation-delay: -1380ms;
            animation-delay: -1380ms;
}
.wave:nth-child(69) .wave_translate {
    -webkit-animation-delay: -2380ms;
            animation-delay: -2380ms;
}
.wave:nth-child(70) .wave_fade {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(70) .wave_skew {
    -webkit-animation-delay: -1400ms;
            animation-delay: -1400ms;
}
.wave:nth-child(70) .wave_translate {
    -webkit-animation-delay: -2400ms;
            animation-delay: -2400ms;
}
.wave:nth-child(71) .wave_fade {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(71) .wave_skew {
    -webkit-animation-delay: -1420ms;
            animation-delay: -1420ms;
}
.wave:nth-child(71) .wave_translate {
    -webkit-animation-delay: -2420ms;
            animation-delay: -2420ms;
}
.wave:nth-child(72) .wave_fade {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(72) .wave_skew {
    -webkit-animation-delay: -1440ms;
            animation-delay: -1440ms;
}
.wave:nth-child(72) .wave_translate {
    -webkit-animation-delay: -2440ms;
            animation-delay: -2440ms;
}
.wave:nth-child(73) .wave_fade {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(73) .wave_skew {
    -webkit-animation-delay: -1460ms;
            animation-delay: -1460ms;
}
.wave:nth-child(73) .wave_translate {
    -webkit-animation-delay: -2460ms;
            animation-delay: -2460ms;
}
.wave:nth-child(74) .wave_fade {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(74) .wave_skew {
    -webkit-animation-delay: -1480ms;
            animation-delay: -1480ms;
}
.wave:nth-child(74) .wave_translate {
    -webkit-animation-delay: -2480ms;
            animation-delay: -2480ms;
}
.wave:nth-child(75) .wave_fade {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(75) .wave_skew {
    -webkit-animation-delay: -1500ms;
            animation-delay: -1500ms;
}
.wave:nth-child(75) .wave_translate {
    -webkit-animation-delay: -2500ms;
            animation-delay: -2500ms;
}
.wave:nth-child(76) .wave_fade {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(76) .wave_skew {
    -webkit-animation-delay: -1520ms;
            animation-delay: -1520ms;
}
.wave:nth-child(76) .wave_translate {
    -webkit-animation-delay: -2520ms;
            animation-delay: -2520ms;
}
.wave:nth-child(77) .wave_fade {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(77) .wave_skew {
    -webkit-animation-delay: -1540ms;
            animation-delay: -1540ms;
}
.wave:nth-child(77) .wave_translate {
    -webkit-animation-delay: -2540ms;
            animation-delay: -2540ms;
}
.wave:nth-child(78) .wave_fade {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(78) .wave_skew {
    -webkit-animation-delay: -1560ms;
            animation-delay: -1560ms;
}
.wave:nth-child(78) .wave_translate {
    -webkit-animation-delay: -2560ms;
            animation-delay: -2560ms;
}
.wave:nth-child(79) .wave_fade {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(79) .wave_skew {
    -webkit-animation-delay: -1580ms;
            animation-delay: -1580ms;
}
.wave:nth-child(79) .wave_translate {
    -webkit-animation-delay: -2580ms;
            animation-delay: -2580ms;
}
.wave:nth-child(80) .wave_fade {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(80) .wave_skew {
    -webkit-animation-delay: -1600ms;
            animation-delay: -1600ms;
}
.wave:nth-child(80) .wave_translate {
    -webkit-animation-delay: -2600ms;
            animation-delay: -2600ms;
}
.wave:nth-child(81) .wave_fade {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(81) .wave_skew {
    -webkit-animation-delay: -1620ms;
            animation-delay: -1620ms;
}
.wave:nth-child(81) .wave_translate {
    -webkit-animation-delay: -2620ms;
            animation-delay: -2620ms;
}
.wave:nth-child(82) .wave_fade {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(82) .wave_skew {
    -webkit-animation-delay: -1640ms;
            animation-delay: -1640ms;
}
.wave:nth-child(82) .wave_translate {
    -webkit-animation-delay: -2640ms;
            animation-delay: -2640ms;
}
.wave:nth-child(83) .wave_fade {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(83) .wave_skew {
    -webkit-animation-delay: -1660ms;
            animation-delay: -1660ms;
}
.wave:nth-child(83) .wave_translate {
    -webkit-animation-delay: -2660ms;
            animation-delay: -2660ms;
}
.wave:nth-child(84) .wave_fade {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(84) .wave_skew {
    -webkit-animation-delay: -1680ms;
            animation-delay: -1680ms;
}
.wave:nth-child(84) .wave_translate {
    -webkit-animation-delay: -2680ms;
            animation-delay: -2680ms;
}
.wave:nth-child(85) .wave_fade {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(85) .wave_skew {
    -webkit-animation-delay: -1700ms;
            animation-delay: -1700ms;
}
.wave:nth-child(85) .wave_translate {
    -webkit-animation-delay: -2700ms;
            animation-delay: -2700ms;
}
.wave:nth-child(86) .wave_fade {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(86) .wave_skew {
    -webkit-animation-delay: -1720ms;
            animation-delay: -1720ms;
}
.wave:nth-child(86) .wave_translate {
    -webkit-animation-delay: -2720ms;
            animation-delay: -2720ms;
}
.wave:nth-child(87) .wave_fade {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(87) .wave_skew {
    -webkit-animation-delay: -1740ms;
            animation-delay: -1740ms;
}
.wave:nth-child(87) .wave_translate {
    -webkit-animation-delay: -2740ms;
            animation-delay: -2740ms;
}
.wave:nth-child(88) .wave_fade {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(88) .wave_skew {
    -webkit-animation-delay: -1760ms;
            animation-delay: -1760ms;
}
.wave:nth-child(88) .wave_translate {
    -webkit-animation-delay: -2760ms;
            animation-delay: -2760ms;
}
.wave:nth-child(89) .wave_fade {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(89) .wave_skew {
    -webkit-animation-delay: -1780ms;
            animation-delay: -1780ms;
}
.wave:nth-child(89) .wave_translate {
    -webkit-animation-delay: -2780ms;
            animation-delay: -2780ms;
}
.wave:nth-child(90) .wave_fade {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(90) .wave_skew {
    -webkit-animation-delay: -1800ms;
            animation-delay: -1800ms;
}
.wave:nth-child(90) .wave_translate {
    -webkit-animation-delay: -2800ms;
            animation-delay: -2800ms;
}
.wave:nth-child(91) .wave_fade {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(91) .wave_skew {
    -webkit-animation-delay: -1820ms;
            animation-delay: -1820ms;
}
.wave:nth-child(91) .wave_translate {
    -webkit-animation-delay: -2820ms;
            animation-delay: -2820ms;
}
.wave:nth-child(92) .wave_fade {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(92) .wave_skew {
    -webkit-animation-delay: -1840ms;
            animation-delay: -1840ms;
}
.wave:nth-child(92) .wave_translate {
    -webkit-animation-delay: -2840ms;
            animation-delay: -2840ms;
}
.wave:nth-child(93) .wave_fade {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(93) .wave_skew {
    -webkit-animation-delay: -1860ms;
            animation-delay: -1860ms;
}
.wave:nth-child(93) .wave_translate {
    -webkit-animation-delay: -2860ms;
            animation-delay: -2860ms;
}
.wave:nth-child(94) .wave_fade {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(94) .wave_skew {
    -webkit-animation-delay: -1880ms;
            animation-delay: -1880ms;
}
.wave:nth-child(94) .wave_translate {
    -webkit-animation-delay: -2880ms;
            animation-delay: -2880ms;
}
.wave:nth-child(95) .wave_fade {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(95) .wave_skew {
    -webkit-animation-delay: -1900ms;
            animation-delay: -1900ms;
}
.wave:nth-child(95) .wave_translate {
    -webkit-animation-delay: -2900ms;
            animation-delay: -2900ms;
}
.wave:nth-child(96) .wave_fade {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(96) .wave_skew {
    -webkit-animation-delay: -1920ms;
            animation-delay: -1920ms;
}
.wave:nth-child(96) .wave_translate {
    -webkit-animation-delay: -2920ms;
            animation-delay: -2920ms;
}
.wave:nth-child(97) .wave_fade {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(97) .wave_skew {
    -webkit-animation-delay: -1940ms;
            animation-delay: -1940ms;
}
.wave:nth-child(97) .wave_translate {
    -webkit-animation-delay: -2940ms;
            animation-delay: -2940ms;
}
.wave:nth-child(98) .wave_fade {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(98) .wave_skew {
    -webkit-animation-delay: -1960ms;
            animation-delay: -1960ms;
}
.wave:nth-child(98) .wave_translate {
    -webkit-animation-delay: -2960ms;
            animation-delay: -2960ms;
}
.wave:nth-child(99) .wave_fade {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(99) .wave_skew {
    -webkit-animation-delay: -1980ms;
            animation-delay: -1980ms;
}
.wave:nth-child(99) .wave_translate {
    -webkit-animation-delay: -2980ms;
            animation-delay: -2980ms;
}
.wave:nth-child(100) .wave_fade {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(100) .wave_skew {
    -webkit-animation-delay: -2000ms;
            animation-delay: -2000ms;
}
.wave:nth-child(100) .wave_translate {
    -webkit-animation-delay: -3000ms;
            animation-delay: -3000ms;
}
@-webkit-keyframes fade {
    0% {
        opacity: 0.05;
   }
    5% {
        opacity: 0.05;
   }
    100% {
        opacity: 1;
   }
}
@keyframes fade {
    0% {
        opacity: 0.05;
   }
    5% {
        opacity: 0.05;
   }
    100% {
        opacity: 1;
   }
}
@-webkit-keyframes skew {
    0% {
        -webkit-transform: skewY(-17.5deg);
                transform: skewY(-17.5deg);
   }
    100% {
        -webkit-transform: skewY(17.5deg);
                transform: skewY(17.5deg);
   }
}
@keyframes skew {
    0% {
        -webkit-transform: skewY(-17.5deg);
                transform: skewY(-17.5deg);
   }
    100% {
        -webkit-transform: skewY(17.5deg);
                transform: skewY(17.5deg);
   }
}
@-webkit-keyframes translate {
    0% {
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
   }
    100% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
   }
}
@keyframes translate {
    0% {
        -webkit-transform: translateY(100px);
                transform: translateY(100px);
   }
    100% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
   }
}
.buy-input .InputSection-static-input {
  font-size: 1.5rem;
}

.buy-input.Exchange-swap-section {
  min-height: 5.935rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buy-input .TokenSelector-box {
  display: flex;
  align-items: center;
  border-radius: 4px;
  /* padding: 0.5rem 0; */
  color: #ffffff;
  min-width: 3rem;
  font-size: 1.25rem;
  line-height: 1.625rem;
  justify-content: flex-end;
}
.buy-input .TokenSelector-box:hover {
  color: rgb(59 130 245);
}

.buy-input .Exchange-swap-input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buy-input .Exchange-swap-max {
  position: unset;
  color: #000;
  font-size: 1rem;
}

.buy-input .TokenSelector-caret {
  margin: 0;
  margin-right: -5px;
}

.buy-input .TokenSelector-box-symbol {
  margin-left: 0.5rem;
}

.buy-input .PositionEditor-token-symbol {
  display: flex;
  align-items: center;
  margin-left: 0.9rem;
}

.buy-input .PositionEditor-token-symbol img {
  margin-left: 0.5rem;
  display: none;
}

.buy-input .Exchange-swap-section-top {
  padding-bottom: 0;
}

.buy-input .selected-token {
  /* padding-top: 0.5rem; */
  /* padding-bottom: 0.5rem; */
  display: flex;
  align-items: center;
}

.buy-input .Exchange-swap-balance {
  color: white;
}

.Checkbox {
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.Checkbox.disabled {
  cursor: default;
  pointer-events: none;
}

.Checkbox-icon-wrapper {
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  top: 0.05rem;
}

.Checkbox .Checkbox-icon {
  font-size: 0.8rem;
  /* color: rgba(35, 31, 32, 1); */
  margin-right: 0.4rem;
  margin-bottom: 0;
}

.Checkbox.disabled .Checkbox-icon {
  color: #fff;
}

.Checkbox-icon.inactive {
  color: rgba(255,255,255,0.7);
}

.Checkbox-label {
  display: inline-block;
  vertical-align: middle;
}

a.btn {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
}
.btn-center {
  justify-content: center;
}
.btn-left {
  justify-content: start;
}
.btn {
  display: inline-flex;
  align-items: center;
  border: none;
  color: white;
  font-size: 14px;
  border-radius: var(--border-radius-sm);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.btn-sm {
  padding: 5px 14px;
}

.btn-lg,
.btn-xl {
  padding: 0.5rem 1rem;
}

.btn-sm .btn-label {
  font-weight: 400;
  font-size: 14px;
  margin-left: 8px;
  letter-spacing: 0;
}
.btn-lg .btn-label {
  margin-left: 0.75rem;
  font-size: 1.1875rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0;
}
.btn-xl .btn-label {
  margin-left: 0.75rem;
  font-size: 1.1875rem;
  font-weight: normal;
  line-height: 1.5rem;
  letter-spacing: 0;
}

.btn-sm .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}
.btn-lg .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
}
.btn-xl .btn-image {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 27px;
  height: 27px;
}

.btn-primary {
  background: transparent;
  border: 1px solid var(--dark-blue-border);
  border-radius: var(--border-radius-sm);
}

.btn-primary:hover {
  background: #00052a;
  border: 1px solid #00052a;
}

.App-header-user .btn-primary:active {
  background: linear-gradient(266.44deg,#28edec,#0564d7) !important;
  border-color: #28edec !important;
}

.App-header-user .btn-check:focus+.btn-primary,
.App-header-user .btn-primary:focus {
  background: linear-gradient(266.44deg,#28edec,#0564d7) !important;
  border-color: #28edec !important;
  box-shadow: 0 0 0 0.25rem #0564d7;
}

/* .btn-primary:active {
  background: #000526;
  border: 1px solid #000526;
  box-shadow: 0 0 0 0.25rem rgb(35 31 32 / 100%);
}
.btn-check:focus+.btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #080b27;
  border-color: #080b27;
  box-shadow: 0 0 0 0.75rem rgb(35 31 32 / 100%);
} */

@media (max-width: 450px) {
  .connect-wallet {
    padding: 5px 8px;
  }
}
@media (max-width: 350px) {
  .connect-wallet .btn-image {
    display: none;
  }
  .connect-wallet .btn-label {
    margin-left: 0px;
  }
  .connect-wallet {
    height: 36px;
  }
}
.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .25rem #0564d7;
}

.Tooltip {
  position: relative;
}

.Tooltip-popup {
  font-size: 0.9rem;
  line-height: 1.032rem;
  position: absolute;
  border-radius: 4px;
  padding: 0.674rem;
  letter-spacing: 0.4px;
  min-width: 250px;
  text-align: left;
  background: #080b27;
  z-index: 1000;
}

.Tooltip.nowrap .Tooltip-popup {
  min-width: auto;
  white-space: nowrap;
}

.Tooltip-popup :first-child {
  margin-top: 0;
}

.Tooltip-popup :last-child {
  margin-bottom: 0;
}

.Tooltip-popup.left-bottom {
  left: 0;
  -webkit-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
  top: 100%;
}

.Tooltip-popup.right-bottom {
  right: 0;
  -webkit-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
  top: 100%;
}

.Tooltip-popup.right-top {
  right: 0;
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
  bottom: 100%;
}
.Tooltip-popup.right {
  left: 30px;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  bottom: 100%;
}

.Tooltip-popup.left-top {
  left: 0;
  -webkit-transform: translateY(-0.5rem);
          transform: translateY(-0.5rem);
  bottom: 100%;
}

.Tooltip-popup.center-bottom {
  left: 50%;
  -webkit-transform: translateY(0.5rem) translateX(-50%);
          transform: translateY(0.5rem) translateX(-50%);
  top: 100%;
}

.Tooltip-popup.center-top {
  left: 50%;
  -webkit-transform: translateY(-0.5rem) translateX(-50%);
          transform: translateY(-0.5rem) translateX(-50%);
  bottom: 100%;
}

.Tooltip-handle {
  cursor: help;
  position: relative;
  display: inline-flex;
  text-decoration: underline;
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
  -webkit-text-decoration-color: rgba(255, 255, 255, 0.6);
          text-decoration-color: rgba(255, 255, 255, 0.6);
}

.Tooltip-handle.plain:after {
  border-bottom: none;
}

.Tooltip.no-underline .Tooltip-handle::after {
  content: unset;
}

@media (max-width: 700px) {
  .Tooltip-popup {
    min-width: 200px;
  }
}

.card {
  padding: 1rem;
  /* border: 1px solid #000526;
  border-radius:15px;
  background: #000526; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.card-header {
  font-size: 1.032rem;
  line-height: 1.355rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: none;
  border-bottom: 1px solid #080b27;
}

.card-divider {
  height: 1px;
  background: #000526;
  margin: 0.7rem -1rem;
}

.card-body {
}

@-webkit-keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
            transform: translate3d(0, -16px, 0);
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    -webkit-transform: translate3d(0, -16px, 0);
            transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3rem 0.2rem;
  background: rgb(59 130 245);
  border-radius: 50%;
  -webkit-animation: bouncing-loader 0.6s infinite alternate;
          animation: bouncing-loader 0.6s infinite alternate;
}

.bouncing-loader > div:nth-child(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}

.Tab.block {
  display: grid;
  grid-auto-flow: column;
  font-size: 0.9rem;
  border-radius: 15px;
  overflow: hidden;
  color: #fff;
  background: #142043db;
  box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01);
}

.Tab-option-icon {
  margin-right: 0.3rem;
  opacity: 0.7;
}

.Tab.block .Tab-option {
  text-align: center;
  padding: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
  cursor: pointer;
}

.Tab.block .Tab-option:hover {
  color: #fff;
  background: #142043;
}

.Tab.block .Tab-option.active {
  opacity: 1;
  pointer-events: none;
  background: rgb(59 130 246);
  color: #fff;
}

.Tab.block .Tab-option.active .Tab-option-icon {
  opacity: 1;
}

.Tab.inline .Tab-option {
  cursor: pointer;
  display: inline-block;
  margin-right: 1rem;
}

.Tab.inline .Tab-option:hover {
  opacity: 0.8;
}

.Tab.inline .Tab-option.active {
  opacity: 1;
  pointer-events: none;
}

.Modal {
  position: fixed;
  z-index: 900;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
}

.Modal-backdrop {
  position: fixed;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.9);
}

.Modal-content {
  z-index: 20;
  position: relative;
  max-width: 100%;
  max-height: 90vh;
  overflow: auto;
  /* background: #080b27;
  border-radius: 15px; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.divider {
  border-bottom: 1px solid #ffffff29;
  margin-bottom: 1rem;
}

.Modal.non-scrollable .Modal-content {
  overflow: visible;
}

.Modal-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}
.Modal-body {
  margin: 1rem;
  margin-bottom: 1.5rem;
}
.Modal-body::-webkit-scrollbar {
  width: 6px;
}

.Modal-body::-webkit-scrollbar-track {
  background-color: #1c1c1c;
  border-radius: 100rem;
}

.Modal-body::-webkit-scrollbar-thumb {
  background-color: #949393;
  border-radius: 100rem;
}

.Modal-title {
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
}

.Modal-close-button {
  text-align: right;
}

.Modal-close-icon {
  opacity: 0.6;
  cursor: pointer;
  text-align: right;
  display: inline-block;
}

.Modal-close-icon:hover {
  opacity: 0.9;
}

.Modal-note {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.TokenSelector .Modal .Modal-content {
  width: 20rem;
  margin: 0;
  max-height: 100vh;
}

.TokenSelector-token-name {
  margin-right: 1rem;
}

.TokenSelector.disabled {
  pointer-events: none;
}

.TokenSelector.disabled .TokenSelector-caret {
  display: none;
}

.TokenSelector-box {
  display: block;
  cursor: pointer;
  border-radius: 6px;
  text-align: right;
  white-space: nowrap;
}

.TokenSelector-box:hover {
  color: rgb(59 130 245);
}

.Token-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.Token-info .Token-symbol {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
}
.Token-balance {
  display: flex;
  flex-direction: column;
  text-align: right;
}
.Token-info .token-logo {
  display: block !important;
  width: 40px;
  height: 40px;
  margin-right: 2px;
  margin-left: 0 !important;
}

.TokenSelector-caret {
  vertical-align: middle;
  display: inline-block;
  margin-left: 0.3rem;
  font-size: 1.5rem;
  margin-top: -0.3rem;
}

.TokenSelector-token-row {
  cursor: pointer;
  border: 1px solid #ffffff29;
  background: transparent;
  border-radius: var(--border-radius-sm);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  margin-bottom: 8px;
}

.TokenSelector-token-row.TokenSelector-token-input-row {
  padding: 0;
}

.TokenSelector-token-row input {
  padding: 8px;
  width: 100%;
}

.TokenSelector-token-row .Token-text {
  font-size: 16px;
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 0px;
}
.TokenSelector-token-row:hover {
  background: rgba(191, 191, 190, 0.659);
}

.TokenSelector.right .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}
.text-accent {
  color: white;
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0;
}

.TokenSelector.right .Modal-content {
  position: absolute;
  width: 23rem;
  top: 0;
  bottom: 0;
  overflow: auto;
  border: none;
}

@media (max-width: 700px) {
  .TokenSelector.right .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 2rem;
    top: 0;
    right: 2rem;
    bottom: 0;
  }
  .Token-info .token-logo {
    width: 30px;
    height: 30px;
    margin-right: 0.5rem;
  }
}

.EquitySwap-content {
  display: flex;
  justify-content: space-between;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.Tab-title-section {
  padding: 2rem 1rem 0;
  margin-bottom: 24px;
}

.Tab-title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 2.2rem;
  font-weight: 500;
  font-family: 'Inter', Consolas;
}

.Tab-description {
  letter-spacing: 0px;
  color: #a9a9b0;
  margin-top: 0.5rem;
}

.Tab-description span {
  color: #f89191;
}

.EquitySwap-from-token .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.EquitySwap-from-token .Modal .Modal-content {
  width: 27rem;
}

.EquitySwap-from-token .Modal-content {
  position: absolute;
  width: 25rem;
  max-height: 100vh;
  overflow: hidden;
  border: none;
  top: 0;
  bottom: 0;
}
.EquitySwap-from-token .Modal-content .Modal-body {
  padding-right: 5px;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-overflow-scrolling: auto;
}

.EquitySwap-content .EquitySwap-stats-card,
.EquitySwap-content .EquitySwap-box {
  width: 100%;
}

.EquitySwap-content .EquitySwap-stats-card {
  max-width: 45.935rem;
  margin-right: 1rem;
}

.EquitySwap-stats-card .App-card-row .label {
  color: #a9a9b0;
  opacity: 1;
}

.EquitySwap-content .EquitySwap-box {
  max-width: 30.935rem;
  border-radius: 15px;
  padding: 1rem;
  padding-top: 0;
}

.EquitySwap-box .Exchange-swap-option-tabs {
  margin: 1rem 0;
}

.App-card-title-mark-icon {
  position: relative;
  margin-right: 0.5rem;
  display: flex;
}

.App-card-title-mark-icon .selected-network-symbol {
  position: absolute;
  bottom: 0;
  right: 0;
  border: 1px solid #000526;
  border-radius: 50%;
}

.App-card-title-mark {
  display: flex;
}

.App-card-title-mark .App-card-title-mark-title {
  font-size: 1.032rem;
  line-height: 1.355rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.App-card-title-mark .App-card-title-mark-subtitle {
  font-size: 0.774rem;
  line-height: 1rem;
  letter-spacing: 0px;
  color: #fff;
}

.EquitySwap-token-list {
  /* border: 1px solid #000526;
  border-radius: 15px;
  background: #000526; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
  padding: 1rem;
  padding-top: 0;
  text-align: left;
  margin-bottom: 70px;
}

.EquitySwap-token-list-content {
  display: grid;
  padding: 1rem;
  padding-top: 0;
  text-align: left;
}

.EquitySwap-box .App-card-title {
  margin-bottom: 0.5rem;
}

.EquitySwap-box .Exchange-info-row {
  height: 1rem;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.EquitySwap-box .Exchange-info-row .Exchange-info-label {
  margin-right: 0.5rem;
  color: #a9a9b0;
  opacity: 1;
}

.EquitySwap-cta {
  margin: 0 auto;
  text-align: center;
  padding-top: 0;
}

.EquitySwap-cta .Exchange-swap-button {
  margin: 0 auto;
  justify-content: center;
  min-height: 3.097rem;
  font-size: 1rem;
}

.token-table {
  margin-left: -1rem;
  margin-right: -1rem;
  width: calc(100% + 2rem);
  border-spacing: 0;
  white-space: nowrap;
}

.token-grid {
  display: none;
}

.token-table td,
.token-table th {
  padding: 0.75rem;
}

.token-table td:nth-child(2),
.token-table th:nth-child(2) {
  width: 110px;
}

.token-table td:nth-child(3),
.token-table th:nth-child(3) {
  width: 320px;
}

.token-table td:nth-child(4),
.token-table th:nth-child(4) {
  width: 200px;
}

.token-table td:nth-child(5),
.token-table th:nth-child(5) {
  width: 130px;
}

.token-table td {
  letter-spacing: 0.25px;
  color: #ffffffde;
}

.token-table th:first-child,
.token-table td:first-child {
  padding-left: 1rem;
  width: 17rem;
}

.token-table th:last-child,
.token-table td:last-child {
  padding-right: 1rem;
  text-align: right;
  width: 170px;
}

.token-table th {
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #a9a9b0;
  text-transform: uppercase;
}

.token-table .action-btn {
  width: 100%;
  justify-content: center;
}
.cap-reached {
  margin-left: 8px;
}

@media (max-width: 900px) {
  .EquitySwap-content {
    flex-wrap: wrap;
  }

  .EquitySwap-content .EquitySwap-stats-card {
    margin: 0;
    margin-bottom: 2rem;
    max-width: 100%;
  }

  .EquitySwap-content .EquitySwap-box {
    margin: 0;
    max-width: 100%;
  }
}

@media (max-width: 1100px) {
  .EquitySwap-from-token .Modal {
    align-items: center;
    justify-content: center;
  }
  .EquitySwap-from-token .Modal .Modal-content {
    width: 27rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }
  .EquitySwap-from-token .Modal .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(80vh - 5.5rem);
    padding-right: 5px;
    margin: 1rem calc(1rem - 5px) 1rem 1rem;
  }

  .EquitySwap-token-list {
    border: none;
    background-color: transparent;
    padding: 0;
  }

  .EquitySwap-token-list-content {
    padding: 0;
  }

  .token-table {
    display: none;
  }

  .token-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    margin-top: 0.5rem;
  }
}
@media (max-width: 510px) {
  .token-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    margin-top: 0.5rem;
  }
}

.asset-menu-items {
  position: absolute;
  border: 1px solid #080b27;
  border-radius: 4px;
  background: var(--dark-blue);
  outline: none;
  z-index: 100;
}

.dropdown-arrow {
  margin-left: 0.5rem;
  cursor: pointer;
  color: white;
  opacity: 0.8;
}
.dropdown-arrow:hover {
  opacity: 1;
}

.asset-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #fff;
  text-decoration: none;
  padding: 8.5px 8px;
  background: #080b27;
}
.asset-item:hover {
  background: #080b27;
  color: rgb(59 130 245);
  border-radius: 4px;
}
.asset-item p {
  margin: 0;
  margin-left: 5px;
}

.event-toast-container .toast-links {
  margin-top: 15px;
  font-size: 14px;
  padding: 0 1rem 1rem 1rem;
}
.zoomIn {
  -webkit-animation: zoomIn 0.2s ease-in-out;
          animation: zoomIn 0.2s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.zoomOut {
  -webkit-animation: zoomOut 0.2s ease-in-out;
          animation: zoomOut 0.2s ease-in-out;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}
.event-toast-container .toast-links a {
  text-decoration: underline;
  font-size: 14px;
  opacity: 0.7;
  margin-right: 1rem;
}
.event-toast-container .toast-links a:hover {
  opacity: 1;
}

.single-toast {
  background: rgb(59 130 245);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  color: white;
  border: none;
  position: relative;
  border-radius: 4px;
  border: 1px solid #ffffff29;
}
.single-toast header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #ffffff29;
}
.single-toast header .toast-title {
  display: flex;
  align-items: center;
}
.single-toast header .cross-icon {
  width: 22px;
  height: 22px;
  cursor: pointer;
  border-radius: 4px;
  padding: 2px;
  margin-left: 1rem;
}
.single-toast header .cross-icon:hover {
  background: #ffffff47;
}
.single-toast header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.single-toast header .announcement-icon {
  width: 20px;
  height: 20px;
  color: white;
}
.single-toast header p {
  margin: 0;
  padding: 0.25rem 0;
  margin-left: 0.75rem;
  font-size: 16px;
}

.single-toast .toast-body {
  margin: 8px 0;
  padding: 0;
  max-width: 300px;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 1rem;
}

@media screen and (max-width: 500px) {
  .event-toast-container {
    inset: 80px 16px 0 16px;
  }
  .single-toast {
    background: rgb(59 130 245);
  }
}

.chart-token-selector {
    padding-top: 0!important;
    padding-bottom: 0!important;
}

.chart-token-selector--current {
    font-size: 1.4rem;
    font-weight: 700;
    margin-right: 10px;
    color: white;
}

.chart-token-menu-items.menu-items {
    width: 158px;
    top: 60px;
    right: unset;
    left: 0;
}

.ExchangeWalletTokens {
  max-height: 100%;
  overflow: auto;
}

.ExchangeWalletTokens-token-name {
  margin-right: 1rem;
}

.ExchangeWalletTokens-box {
  display: block;
  cursor: pointer;
  border-radius: 6px;
  text-align: right;
  white-space: nowrap;
}

.ExchangeWalletTokens-box:hover {
  color: #8763ff;
}

.ExchangeWalletTokens-token-row {
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.ExchangeWalletTokens-token-row:hover {
  background: linear-gradient(90deg, rgba(93,43,255,0.5) 0%, rgba(184,15,150,0.5) 100%);
}

.ExchangeWalletTokens-top-row {
  font-size: 1rem;
  display: grid;
  grid-template-columns: auto auto;
}

.ExchangeWalletTokens-content-row {
  margin-top: 0.2rem;
  font-size: 0.9rem;
  opacity: 0.7;
  display: grid;
  grid-template-columns: auto auto;
}

.Orders-list {
  width: 100%;
  background: linear-gradient(45deg, rgba(11,5,55,0.6) 0%, rgba(21,3,48,0.6) 100%);
  font-size: 0.9rem;
}

.Orders-list.small {
	display: none;
}

table.Orders-list {
  border-collapse: collapse;
  border: 1px solid #1c0e6a;
}

table.Orders-list th, table.Orders-list td {
  font-weight: normal;
  text-align: left;
  padding: 0.7rem 1rem;
}

table.Orders-list tr {
  background: linear-gradient(90deg, rgba(57,28,147,0.1) 0%, rgba(66,24,140,0.1) 100%);
  border-bottom: 1px solid rgba(28, 14, 106, 0.5);
}

table.Orders-list tr:first-child {
  border-bottom: 1px solid rgba(28, 14, 106, 1);
}

table.Orders-list tr:last-child {
  border-bottom: 1px solid rgba(28, 14, 106, 1);
}

.Orders-list-item:hover {
  background: linear-gradient(90deg, rgba(93,43,255,0.5) 0%, rgba(184,15,150,0.5) 100%);
}

.Orders-list-item-error {
  color: #ff2491;
}

.Orders-list-item-type {
  width: 60px;
}

table.Orders-list tr.Orders-list-header {
  background: none;
}

table.Orders-list th {
  opacity: 0.7;
}

button.Orders-list-action {
  font-size: 0.9rem;
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: #8b7fe3;
  opacity: 0.9;
}

button.Orders-list-action:hover {
  color: #d4b3f5;
  opacity: 1;
}

.Orders-list-modal-button {
  margin-top: 10px;
}

/* don't know how to solve other way */
.Orders-list-modal .Exchange-swap-input {
  width: 180px;
}

.Orders-list-modal-warning {
  color: #fa3c58;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
}

.Orders-list-modal .Modal-content {
  width: 22rem;
}

@media (max-width: 1000px) {
  .Orders-list.small {
    display: block;
  }

  .Orders-list.large {
  	display: none;
  }
}

.Orders-toa .Modal-content {
  width: 22rem;
}

.Orders-toa-accept-rules {
  margin-top: 0.2rem;
}

.Orders-toa-accept-rules .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.Orders-toa-accept-rules .Checkbox-label {
  margin-right: 0.5rem;
}

.TradeHistory-row {
  font-size: 0.9rem;
  padding: 1rem;
  margin-bottom: 0.5rem;
}

.TradeHistory-time {
  font-size: 0.8rem;
  margin-bottom: 0.1rem;
}
.textcolor{
  color: white;
}
.textcolor:hover{
  color: rgb(59 130 245);
}
.InputSection-static-input {
  font-size: 1.5rem;
}

.Selector.disabled {
  pointer-events: none;
}

.Selector.disabled .Selector-caret {
  display: none;
}

.Selector .Modal-backdrop {
  height: 100vh;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  background: #080b2774;
}

.Selector-box {
  width: 72px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  padding: 7px 8px;
}

.Selector-box.Fantom {
  /* background-color: #1d446b; */
  border: 1px solid #ffffff17;
 
}

.Selector-caret {
  vertical-align: middle;
  display: inline-block;
  font-size: 1.5rem;
  margin-top: -0.2rem;
}

.Selector-option {
  cursor: pointer;
  /* background: var(--dark-blue); */
  border: 1px solid var(--dark-blue-border);
  border-radius: 4px;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  position: relative;
}
.Selector-option:hover {
  background: var(--dark-blue-hover);
}

.Selector-option + .Selector-option {
  margin-top: 0.5rem;
}

.Selector-option .Selector-option_icon {
  width: 32px;
  margin-right: 1rem;
}

.Selector-option .Selector-option_label {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.29px;
  color: #ffffff;
}

.Selector-option.Fantom:hover {
  background: #000526 !important;
  border: 1px solid #000526;
}


.Selector-option .selected-icon {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 1rem;
}

.Selector-option.Fantom .selected-icon {
  background-color: rgb(59 130 245);
}


.selected-icon {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  right: 1rem;
}

.Selector .Modal {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.Selector .Modal-title {
  color: white;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  opacity: 1;
}

.Selector .Modal-content {
  width: 100%;
  max-width: 19.1rem;
  margin: auto;
/*   background: #16182e 0% 0% no-repeat padding-box; */
  border: 1px solid #080b27;
  border-radius: 4px;
}

.react-select__option {
  border: 1px solid transparent;
  border-radius: 4px;
}
.react-select__option.fantom:hover {
  border: 1px solid #000526;
  border-radius: 4px;
  color: #eee;
}
.react-select__option.fantom:active {
  background: #000526 !important;
}

.react-select__indicator {
  border-radius: 4px;
}
.network-select {
  background: transparent;
}
.network-select:hover {
  background: #000526;
  border-radius: 4px;
}
.network-select:active {
  background: #000526;
  border-radius: 4px;
}
.App-header-user-settings:hover {
  background: #2c2d45;
    border-radius: 4px;
}

@media (max-width: 900px) {
  .Selector .network-label {
    display: none;
  }
}

.Overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Overlay-backdrop {
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

.Overlay-top-bar {
  background: red;
  height: 1rem;
  padding: 0.8rem;
  display: grid;
  grid-template-columns: auto auto;
  background: linear-gradient(90deg, rgba(12,9,78,1) 0%, rgba(45,8,84,1) 100%);
  border-bottom: 1px solid #1f053b;
}

.Overlay-title {
  opacity: 0.8;

}

.Overlay-content-outer {
  position: relative;
  z-index: 20;
  background: linear-gradient(45deg, rgba(7,5,57,1) 0%, rgba(34,3,66,1) 100%);
}

.Overlay-close-button {
  text-align: right;
  display: inline-block;
  opacity: 0.7;
  cursor: pointer;
}

.Overlay-close-button:hover {
  opacity: 0.9;
}

.Radio-option {
  display: inline-block;
  margin-right: 2rem;
  cursor: pointer;
  color: #6b40ed;
}

.Radio-option-bubble {
  display: inline-block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 1rem;
  vertical-align: middle;
  margin-right: 0.4rem;
  margin-bottom: 0.2rem;
  border: 2px solid #6b40ed;
}

.Radio-option-bubble-inner {
  display: none;
  background: #ff369a;
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 1rem;
  margin-left: 0.2rem;
  margin-top: 0.2rem;
}

.Radio-option.active {
  color: #ff369a;
}

.Radio-option.active .Radio-option-bubble {
  border-color: #ff369a;
}

.Radio-option.active .Radio-option-bubble-inner {
  display: block;
}


.warning-banner{
    --tw-bg-opacity: 1;
    background-color: #e8213f;
    text-align:center;
}
.warning-banner p{
    font-size: 1.125rem;
    line-height: 1.75rem;
    margin-bottom: 0;
    padding:1.25rem;
    font-weight: 400;
}
.Actions {
  padding: 0 2.3rem;
  padding-top: 2rem;
}

.Actions-section {
  padding-bottom: 3rem;
}

.Actions-title {
  margin-bottom: 1rem;
}

.Actions input {
  font-size: 1rem;
  padding: 0;
  margin-left: 0.5rem;
}

.Actions .PositionsList .Exchange-list-action {
  display: none;
}

.Actions .PositionsList .App-button-option {
  display: none;
}

.Exchange {
  max-width: 100rem;
  margin: auto;
  justify-content: space-between;
}

.Exchange-list-tab-container {
  display: grid;
  grid-template-columns: auto auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin-top: 1rem;
  margin-bottom: 0.7rem;
}

.Exchange-should-show-position-lines {
  font-size: 0.9rem;
}

.Exchange-empty-positions-list-note {
  padding-bottom: 0.3rem;
}

.position-loading-icon {
  display: inline-block;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  color: #3749e6;
  vertical-align: middle;
  margin-top: -0.2rem;
}

.Exchange-settings-row {
  margin-bottom: 0.5rem;
}

.Exchange-settings .App-cta {
  margin-top: 1rem;
}

.Exchange-content {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1rem;
  padding: 2rem;
  padding-top: 2rem;
}

.Exchange-position-list-orders {
  white-space: nowrap;
}

.Exchange-lists.large {
  display: block;
}

.Exchange-lists.small {
  display: none;
}

.Exchange-list-tabs {
}

.Exchange-right {
  display: grid;
  grid-template-rows: auto 1fr;
}

.Exchange-leverage-slider-settings {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.Exchange-leverage-slider-settings .Checkbox {
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
}

.Exchange-wallet-tokens {
  position: relative;
  min-height: 10rem;
  display: none;
}

.Exchange-wallet-tokens-content {
  position: absolute;
  top: 1rem;
  bottom: 0;
  left: 0;
  right: 0;
}

.Exchange-bottom-header-item {
  display: inline-block;
  margin-right: 1rem;
  margin-bottom: 1rem;
  padding-left: 0.1rem;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-bottom-header-item.active {
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-connect-wallet {
  text-align: center;
  cursor: pointer;
  border: 1px solid rgb(50, 50, 50);
  border-radius: 3px;
}

.Exchange-swap-connect-wallet:hover {
  background: #272e33;
  border-radius: 6px;
}

.Exchange-swap-settings.Exchange-swap-txns-status {
  text-align: right;
  margin-right: 1rem;
}

.Exchange-swap-account .Exchange-swap-address:hover,
.Exchange-swap-account .Exchange-swap-txns-status:hover {
  opacity: 1;
}

.Exchange-leverage-slider {
  margin-top: 1rem;
  margin-bottom: 2.2rem;
  padding: 0 0.5rem;
}

.Exchange-swap-leverage-options {
  text-align: right;
}

.Exchange-swap-leverage-option {
  display: inline-block;
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 3px;
  background: #5b2ab0;
  color: white;
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-swap-leverage-option.active {
  background: #842ec9;
  opacity: 1;
  pointer-events: none;
}

.Exchange-swap-leverage-option:hover {
  opacity: 1;
}

.Exchange-swap-leverage-unlock-icon {
  font-size: 0.5rem;
  vertical-align: middle;
  margin-top: -0.2rem;
}

.Exchange-swap-placeholder {
  height: 7.212125rem;
  position: relative;
}

.Exchange-wave {
  z-index: 1;
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  bottom: 1rem;
  -webkit-mask-image: url(/static/media/wave-01.27a19dfe.png);
          mask-image: url(/static/media/wave-01.27a19dfe.png);
  -webkit-mask-size: 90% 90%;
          mask-size: 90% 90%;
  -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
  -webkit-mask-position: center;
          mask-position: center;
  -webkit-filter: blur(50px);
          filter: blur(50px);
  background: linear-gradient(
    40deg,
    rgba(96, 4, 189, 1) 0%,
    rgba(65, 105, 224, 1) 22%,
    rgba(99, 8, 191, 1) 23%,
    rgba(51, 9, 115, 1) 37%,
    rgba(255, 36, 145, 1) 38%,
    rgba(201, 16, 161, 1) 50%,
    rgba(171, 10, 163, 1) 59%,
    rgba(117, 4, 128, 1) 67%,
    rgba(96, 29, 196, 1) 68%,
    rgba(92, 55, 204, 1) 84%,
    rgba(84, 88, 214, 1) 100%
  );
  pointer-events: none;
}

.PositionEditor-info-box {
  margin-bottom: 0.7rem;
}

.PositionEditor-keep-leverage-settings .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.PositionEditor-accept-profit-warning .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.PositionEditor-allow-higher-slippage .Checkbox {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  direction: rtl;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.ExchangeChart-range-option {
  display: inline-block;
  margin-right: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
  opacity: 0.6;
}

.ExchangeChart-range-option:hover {
  opacity: 0.8;
}

.ExchangeChart-range-option.active {
  opacity: 1;
}

.ExchangeChart {
  position: relative;
  height: 32rem;
  font-family: 'Inter', sans-serif !important;
}

.ExchangeChart-main-price {
  font-size: 1.1rem;
}

.ExchangeChart-title {
  font-size: 1.4rem;
  font-weight: bold;
}

.ExchangeChart-info-label {
  font-size: 0.8rem;
  opacity: 0.7;
}

.ExchangeChart-top-inner {
  display: grid;
  grid-template-columns: auto auto auto auto auto 1fr;
  grid-column-gap: 3rem;
  align-items: center;
}

.ExchangeChart-dollar-sign {
  display: inline-block;
  margin-right: 0.3rem;
  opacity: 0.7;
}

.ExchangeChart-main-price-text {
  display: inline-block;
}

.ExchangeChart-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: auto;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-top {
  position: relative;
  padding: 0.6rem 0.2rem;
  z-index: 2;
}

.ExchangeChart.tv .ExchangeChart-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 4.5rem;
  z-index: 1;
}
.ExchangeChart-bottom-controls {
  display: inline-block;
}
.ExchangeChart-bottom-controls .Tab .Tab-option {
  width: 3rem;
  padding: 0.5rem 0;
}
.ExchangeChart.tv .ExchangeChart-bottom-header {
  position: absolute;
  display: flex;
  top: 1rem;
  left: 1rem;
  right: 1rem;
  z-index: 5;
}
.ExchangeChart.tv .ExchangeChart-bottom-stats {
  font-size: 14px;
  padding: 0.5rem 0;
  margin-left: 2rem;
  white-space: nowrap;
  z-index: 2;
}
.ExchangeChart-bottom-stats-label {
  color: rgba(255, 255, 255, 0.7);
  font-weight: bold;
}
.ExchangeChart-bottom-stats-value {
  display: inline-block;
  margin-left: 0.2rem;
  margin-right: 0.5rem;
}
.length-5 .ExchangeChart-bottom-stats-value {
  width: 4rem;
}
.length-4 .ExchangeChart-bottom-stats-value {
  width: 3.4rem;
}
.length-3 .ExchangeChart-bottom-stats-value {
  width: 2.8rem;
}
.length-2 .ExchangeChart-bottom-stats-value {
  width: 2.2rem;
}
.length-1 .ExchangeChart-bottom-stats-value {
  width: 1.6rem;
}

.ExchangeChart.tv .ExchangeChart-bottom-content {
  position: absolute;
  bottom: 0.5rem;
  left: 0;
  right: 0;
  top: 0.5rem;
}

.Exchange-price-tooltip {
  font-size: 0.9rem;
  background: linear-gradient(90deg, rgba(57, 28, 147, 0.9) 0%, rgba(66, 24, 140, 0.9) 100%);
  padding: 0.6rem;
  border: 1px solid rgba(30, 9, 94, 0.5);
  line-height: 1.3rem;
}

.Exchange-price-time {
  font-size: 0.8rem;
  font-weight: normal;
}

.Error-modal .Modal-content {
  width: 20rem;
}

.PositionEditor .Modal-content {
  width: 27rem;
  position: absolute;
  max-height: 80vh;
  overflow: hidden;
  border: none;
  top: auto;
  bottom: auto;
}

.PositionEditor .Modal-content .Modal-body {
  overscroll-behavior: smooth;
  max-height: calc(80vh - 5.5rem);
  overflow-y: auto;
  padding-right: 5px;
}

.PositionEditor .Tab {
  margin-bottom: 0.7rem;
}

.PositionEditor-token-symbol {
  font-size: 1.35rem;
  text-align: right;
}

.ExchangeChart-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.Exchange-list-modal .Modal-content {
  width: 22rem;
}

.Exchange-list {
  width: 100%;
}

.Position-list-order {
  white-space: nowrap;
}

.Exchange-list .App-card {
  margin-bottom: 1rem;
}

.Exchange-list-item-error {
  color: #fa3c58;
  margin-top: 0.3rem;
  font-size: 0.9rem;
}

.Exchange-list.small {
  display: none;
}

button.Exchange-list-action {
  font-size: 0.95rem;
  padding: 0;
  border-radius: 3px;
  background: none;
  border: none;
  color: rgba(255, 255, 255, 0.7);
  margin-right: 1rem;
}

button.Exchange-list-action:hover {
  color: rgba(255, 255, 255, 1);
}

table.Exchange-list {
  border-collapse: collapse;
  border-color: transparent;
}

table.Exchange-list th,
table.Exchange-list td {
  font-weight: normal;
  text-align: left;
  padding: 0.7rem;
  padding-left: 1rem;
  border-radius: 15px;
}
table.Exchange-list.Orders th,
table.Exchange-list.Orders td {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

table.Exchange-list tr {
  border-bottom: 1px solid rgb(28 36 75);
 
}

table.Exchange-list tr:last-child {
  border-bottom: none;
}

table.Exchange-list tr:hover {
  background: #142043;
  /* box-shadow: inset 0px 0px 30px 5px rgba(255, 255, 255, 0.01); */
 
  
}

table.Exchange-list tr.Exchange-list-header {
  background: none;
}

table.Exchange-list tr.Exchange-list-header th {
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
}

table.Exchange-list th {
  opacity: 0.7;
}

.Exchange-leverage-box {
  margin-bottom: 0.7rem;
}

.Exchange-swap-box-info {
  margin-bottom: 0.7rem;
}

.Exchange-info-row {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 0.9rem;
  margin-bottom: 0.3rem;
}

.Exchange-info-row.top-line {
  border-top: 1px solid #080b27;
  margin-top: 1rem;
  padding-top: 1rem;
}

.Exchange-info-label-button {
  opacity: 0.7;
  cursor: pointer;
}

.Exchange-info-label-button a {
  text-decoration: none;
  color: white;
}
.Exchange-info-label-button a:hover {
  text-decoration: none;
  color: rgb(59 130 245);
}

.Exchange-info-label-button:hover {
  opacity: 0.9;
  
}

.Exchange-info-label {
  opacity: 0.7;
  margin-right: 0.5rem;
}
.Exchange-info-value-warning {
  color: #fa3c58;
}
.Confirmation-box-warning,
.Confirmation-box-warning a {
  color: #fa3c58;
  font-size: 0.9rem;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 1rem;
}

.Exchange-swap-usd {
  display: inline-block;
  font-size: 0.9rem;
}

.Exchange-swap-box {
  width: 27rem;
  display: grid;
  position: relative;
}

.Exchange-swap-market-box-title {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.Exchange-swap-box-inner {
  padding: 1rem;
}

.Exchange-swap-market-box {
  padding: 1rem;
  margin-top: 0.8rem;
  padding-bottom: 1rem;
}

.Exchange-swap-market-box .App-card-divider {
  margin: 0 -1rem 1rem;
}

.Exchange-swap-box .Radio {
  margin-bottom: 0.7rem;
}

.Exchange-swap-box .Overlay-content-outer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10rem;
}

.Exchange-swap-box .Overlay-content {
  padding: 0.7rem;
}

.Exchange-swap-box .TokenSelector .Modal,
.Selector .Modal {
  align-items: flex-start;
  justify-content: flex-end;
}

.Exchange-swap-box .TokenSelector .Modal-content,
.Selector .Modal-content {
  width: 27rem;
  position: absolute;
  max-height: 100vh;
  top: 0;
  bottom: 0;
  overflow: hidden;
  border: none;
}
.Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
  scroll-margin-right: 25rem;
  overscroll-behavior: smooth;
  scrollbar-width: thin;
  padding-right: 5px;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
}

.Selector .Modal-content {
  /* height: max-content; */
}

.Exchange-swap-box .Tab {
  margin-bottom: 0.7rem;
}

.Exchange-swap-section {
  padding: 1rem;
  margin-bottom: 0.7rem;
  border-radius: 20px;
  background: #142043;
  box-shadow: inset 0px 0px 30px 5px rgba(35, 31, 32, 0.01);
}

.Exchange-swap-option-tabs.Tab.block .Tab-option {
  padding: 0.7rem;
}

.Exchange-swap-order-type-tabs {
  margin-top: 1rem;
}

.Exchange-swap-option-tabs.Tab.block .Tab-option-icon {
  margin-top: -0.1rem;
  -webkit-transform: scale(0.75);
          transform: scale(0.75);
  vertical-align: middle;
  margin-right: 0.5rem;
}

.Exchange-swap-section-top {
  display: grid;
  grid-template-columns: auto auto;
  font-size: 0.9rem;
  padding-bottom: 0.8rem;
}

.Exchange-swap-section-bottom {
  display: grid;
  grid-template-columns: 1fr auto;
  padding-bottom: 0.2rem;
}

.Exchange-swap-message {
  font-size: 0.9rem;
  opacity: 0.7;
  margin: 5px 0;
}

.Exchange-swap-input-container {
  position: relative;
  overflow: hidden;
}

.Exchange-swap-max {
  position: absolute;
  right: 0.8rem;
  top: 0;
  padding: 0.3rem 0.5rem;
  color: #000;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  background: rgb(59 130 245);
}

.Exchange-swap-max:hover {
  color: #000;
  background: rgb(82 147 254);
}

.Exchange-swap-section-bottom .TokenSelector-box {
  font-size: 1.35rem;
}

.Exchange-leverage-box .TokenSelector-caret {
  font-size: 1rem;
  margin-left: 0.2rem;
}

input.Exchange-swap-input {
  padding: 0;
  max-width: 100%;
  font-size: 1.5rem;
  width: 100%;
}
input.Exchange-swap-input.small {
  max-width: 9.5rem;
}

.Exchange-swap-button-container {
  padding-top: 0.2rem;
}

.Exchange-swap-button {
  display: block;
  width: 100%;
}

.Exchange-swap-ball-container {
  position: relative;
  z-index: 1;
}

.Exchange-swap-ball-icon {
  text-align: center;
  display: block;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  font-size: 1.3rem;
  opacity: 0.7;
}

.Exchange-swap-ball {
  position: absolute;
  width: 2.3rem;
  height: 2.3rem;
  left: 50%;
  margin-left: -1.15rem;
  top: -1.25rem;
  border-radius: 2rem;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  background: rgb(59 130 245);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}

.Exchange-swap-ball:hover {
  background: rgb(82 147 254);
}

.Exchange-swap-ball:hover .Exchange-swap-ball-icon {
  opacity: 1;
}

table.Exchange-list-small {
  width: 100%;
  background: linear-gradient(45deg, rgba(11, 5, 55, 0.6) 0%, rgba(21, 3, 48, 0.6) 100%);
}

table.Exchange-list-small th,
table.Exchange-list-small td {
  font-weight: normal;
  text-align: left;
  padding: 0.7rem;
}

.Exchange-list-card-header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.5rem;
}

.Exchange-list-title {
  width: 2rem;
  display: inline-block;
  white-space: nowrap;
}

.Exchange-list-info-label {
  font-size: 0.9rem;
  margin-top: 0.2rem;
}

.Exchange-list-muted {
  font-size: 0.9rem;
}

.Exchange-list-card .Exchange-list-side {
  text-align: right;
}

.Exchange-list-card-main-info {
}

.Exchange-list-card .Exchange-list-size-info {
  margin-bottom: 0.5rem;
}

.Confirmation-box {
  font-size: 20px;
}

.Confirmation-box-main {
  text-align: center;
  display: flex;
  flex-direction: column;
  margin: 1rem 0 1.5rem;
}
.Confirmation-box-main-icon {
  margin: 5px 0;
}
.Confirmation-box-main-icon:after {
  content: "↓";
}
.Confirmation-box-main-icon.dot:after {
  content: "•";
}

.Confirmation-box .Modal-content {
  width: 22rem;
}

.Confirmation-box-row {
  margin-top: 1rem;
}

.Confirmation-box-button {
  margin-top: 10px;
  width: 100%;
}

.Exchange-footer {
  text-align: center;
  padding-top: 2rem;
  height: 5rem;
}

@media (max-width: 1500px) {
  .Exchange-swap-box {
    width: 25rem;
  }
}

@media (max-width: 1300px) {
  .Exchange-list.large {
    display: none;
  }

  .Exchange-list.small {
    display: table;
  }
}

@media (max-width: 1100px) {
  .Exchange-swap-box {
    width: auto;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-header {
    display: block;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-stats {
    margin-left: 0;
  }

  .ExchangeChart.tv .ExchangeChart-bottom-content {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    right: 0;
    top: 2.5rem;
  }

  .Exchange-list-tabs {
    margin-top: 0;
  }

  .Exchange-wallet-tokens {
    display: none;
  }

  .Exchange-swap-placeholder {
    display: none;
  }

  .ExchangeChart {
    height: 25rem;
  }

  .Exchange-content {
    grid-template-columns: 1fr;
  }

  .Exchange-lists.large {
    display: none;
  }

  .Exchange-lists.small {
    display: block;
  }

  .Exchange-list-title {
  }

  .Exchange-swap-box .TokenSelector .Modal {
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Exchange-swap-box .Selector .Modal-content {
    /* display: block;
    margin: 0;
    padding: 1rem;
    box-sizing: border-box;
    height: auto;
    border: 1px solid #1c0e6a;
    width: 100%; */
  }

  .Exchange-swap-box .TokenSelector .Modal-content,
  .Selector .Modal-content {
    width: 27rem;
    position: absolute;
    max-height: 80vh;
    overflow: hidden;
    border: none;
    top: auto;
    bottom: auto;
  }

  .Exchange-swap-box .TokenSelector .Modal-content .Modal-body {
    overflow-y: auto;
    max-height: calc(70vh - 5.5rem);
    scroll-margin-right: 25rem;
    overscroll-behavior: smooth;
    scrollbar-width: thin;
    padding-right: 5px;
    margin: 1rem calc(1rem - 5px) 1rem 1rem;
  }

  .Exchange-swap-box .TokenSelector .Modal,
  .Selector .Modal {
    align-items: center;
    justify-content: center;
  }

  input.Exchange-swap-input {
    max-width: 10rem;
  }

  .ExchangeChart-bottom-controls .Tab .Tab-option {
    width: 2.5rem;
    padding: 0.3rem 0;
    font-size: 0.8rem;
  }
}

@media (max-width: 700px) {
  .PositionEditor .Modal-content {
    width: 90vw;
  }

  .ExchangeChart-top-inner {
    grid-template-columns: auto auto auto;
    grid-column-gap: 0.5rem;
  }

  .ExchangeChart-title {
    font-size: 1.2rem;
  }

  .ExchangeChart-additional-info {
    display: none;
  }

  .Exchange-content {
    padding: 1rem;
    padding-top: 1rem;
  }
  .Exchange-swap-box .TokenSelector .Modal {
    margin: 0 1rem;
  }
}

.ValidationRow {
  display: grid;
  grid-template-columns: auto 1fr;
}

.ValidationRow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ValidationRow-icon {
  margin-right: 1rem;
}

.BeginAccountTransfer .ValidationRow {
  margin-bottom: 1rem;
}

.BeginAccountTransfer-validations {
  margin-bottom: 1.5rem;
}

.BeginAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
}

.BuyEQUALEQUITY {
    /* background: #080b27; */
    justify-content: space-between;
}

.BuyEQUALEQUITY-container {
    padding: 3rem 0 2rem 0;
    width: 100%;
}

.BuyEQUALEQUITY-container .section-title-content {
    justify-content: flex-start;
}

@media (max-width: 425px) {
    .BuyEQUALEQUITY-container {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }
}
.buypage-container-pd{
    padding-left: 16px!important;
    padding-right: 16px!important;
}

.default-btnhome.read-more {
    color: rgb(10 211 215) !important;
    border: 1px solid rgba(10, 211, 215, 0.6) !important;
    background: none !important;
    border-radius: 0.5rem !important;
    transition-timing-function: linear;
    transition-property: all;
    transition-delay: 150ms;
  }

.default-btnhome.read-more:hover {
    color: rgb(10 211 215) !important;
    border: 1px solid rgba(10, 211, 215, 1) !important;
    background: none !important;  }

.default-btnhome {
    border-radius: 0.5rem !important;
  }
.BuyEQUAL {
    /*background: #101124;*/
    min-height: calc(100vh - 62px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.BuyEQUAL-container {
    padding: 3rem 0 2rem 0;
    text-align: center;
    max-width: 70rem!important;
}

.BuyEQUAL-title {
    font-size: 2.2rem;
    line-height: 3.097rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 1.5484rem;
    font-family: 'Inter', Consolas, sans-serif;
    font-weight: 600;
    /*font-family: 'Relative Bold';*/
}

.BuyEQUAL-description {
    /* font-size: 1.161rem; */
    line-height: 1.484rem;
    letter-spacing: 0px;
    color: #fff;
}

.better-rates-description {
    margin-top: 1.548rem;
    letter-spacing: 0;
}

.BuyEQUAL-description a {
    text-decoration: none;
    position: relative;
}

.alternative-bridges {
    margin: 1rem auto;
    max-width: 728px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.alternative-bridges a {
    display: flex;
    position: relative;
}

.alternative-bridges a.Synapse:hover::before,
.alternative-bridges a.Synapse:focus::before,
.alternative-bridges a.Multichain:hover::before,
.alternative-bridges a.Multichain:focus::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.textcolorwhite:hover{
    color: #fff;
}
.textcolorwhite:after{
    color: #fff;
}
.textcolorwhite{
    color: #fff;
}

.BuyEQUAL-purchase-block {
    background-image: url(/static/media/btn_big_purchasemvx.cadc61f1.svg);
    padding-top: 2.1897rem;
    padding-left: 2.826rem;
    max-width: 397px;
    width: 100%;
    min-height: 120px;
    margin: 1.548rem auto;
    margin-bottom: 3rem;
    display: flex;
    box-shadow: 0px 9px 12px #29282854;
    border: 1px solid #000526;
    border-radius: 4px;
    text-decoration: none;
    position: relative;
}

.BuyEQUAL-purchase-block:hover::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BuyEQUAL-purchase-block:active::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.BuyEQUAL-purchase-block-info {
    margin-left: 2.461rem;
    text-align: left;
}

.BuyEQUAL-purchase-block-info__title {
    font-size: 1.677rem;
    line-height: 2.129rem;
    letter-spacing: 0px;
    color: #080b27;
}

.BuyEQUAL-purchase-block-info__subtitle {
    color: #080b27;
    font-size: 1.161rem;
    line-height: 1.484rem;
    letter-spacing: 0px;
}

.BuyEQUAL-title-section .EQUITY-block-section {
    display: flex;
    max-width: 416px;
    justify-content: space-between;
    margin: 0 auto;
    margin-top: 1.548rem;
}

.EQUITY-block-section .EQUITY-block {
    background-image: url(/static/media/btn_big_purchasemvx.cadc61f1.svg);
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 60px;
    border-radius: 4px;
    text-decoration: none;
    position: relative;
}

.EQUITY-block-section .EQUITY-block:hover::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.EQUITY-block-section .EQUITY-block:active::before {
    content: " ";
    position: absolute;
    background: #bfbfbe41;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: 4px;
}

.EQUITY-block .EQUITY-block-icon {
    display: flex;
}

.EQUITY-block .EQUITY-block-label {
    color: #080b27;
    font-size: 1.161rem;
    line-height: 1.484rem;
    margin-left: 0.516rem;
}

@media (max-width: 900px) {
    .alternative-bridges {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .alternative-bridges a + a {
        margin-top: 1.548rem;
    }

    .BuyEQUAL-purchase-block {
        padding-left: 1.859rem;
    }

    .BuyEQUAL-title-section .EQUITY-block-section {
        flex-direction: column;
        align-items: center;
    }

    .EQUITY-block-section .EQUITY-block + .EQUITY-block {
        margin-top: 16px;
    }
}

@media (max-width: 400px) {
    .BuyEQUAL-container {
        padding-left: 16px!important;
        padding-right: 16px!important;
    }

    .BuyEQUAL-description {
        font-size: 1rem;
        line-height: 1.3rem;
    }

    .BuyEQUAL-purchase-block {
        padding-left: 1.5rem;
    }

    .BuyEQUAL-purchase-block-info {
        margin-left: 1.5rem;
        text-align: left;
    }

    .BuyEQUAL-purchase-block-info__title {
        font-size: 1.5rem;
        line-height: 2rem;
    }

    .BuyEQUAL-purchase-block-info__subtitle {
        font-size: 1rem;
        line-height: 1.3rem;
    }
}
.marginbottombyequal{
    margin-bottom: 70px;
}
.BuyEQUAL-container-pdsmallscreen{
    padding-left: 16px!important;
    padding-right: 16px!important;
}

.buy-equity-content {
    padding-top: 3rem;
}
.ahreftextcolor{
    color: white;
  }
  .ahreftextcolor:hover{
    color: white;
  }
  
.ClaimEsEqual input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.ClaimEsEqual label {
  display: block;
  margin-bottom: 0.5rem;
}

.ClaimEsEqual-content {
  padding: 3rem;
  padding-top: 1rem;
  max-width: 25rem;
}

.ClaimEsEqual-row {
  margin-bottom: 1rem;
}

.ClaimEsEqual button {
  margin-top: 0.5rem;
}

.ClaimEsEqual-vesting-options .Checkbox.btn-lg {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: block;
  width: 23rem;
  padding-left: 1.2rem;
  padding-top: 0.3rem;
}

.ClaimEsEqual-vesting-options .Checkbox.btn-lg.selected.fantom {
  background: rgba(41, 160, 240, 0.2);
}

.ClaimEsEqual-vesting-options .Checkbox img {
  height: 1rem;
}

.ClaimEsEqual-option-label {
  display: inline-block;
  width: 18rem;
  text-align: center;
  opacity: 0.7;
}

.ClaimEsEqual-vesting-options .Checkbox.selected .ClaimEsEqual-option-label {
  opacity: 1;
}

.ClaimEsEqual-vesting-options .Checkbox.fantom .Checkbox-icon.active {
  color: #29a0f0;
}

.ClaimEsEqual button {
  max-width: 23rem;
}

.ClaimEsEqual-input-label {
  margin-bottom: 0.5rem;
}

.ClaimEsEqual input {
  width: 23rem;
}

.ClaimEsEqual-input-container {
  position: relative;
}

.ClaimEsEqual-max-button {
  position: absolute;
  padding: 0.3rem 0.5rem;
  background: linear-gradient(
    90deg,
    rgba(45, 66, 252, 1) 0%,
    rgba(46, 61, 205, 1) 100%
  );
  left: 19.2rem;
  top: 0.6rem;
  font-size: 0.9rem;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
}

.ClaimEsEqual-max-button:hover {
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(
    90deg,
    rgba(58, 78, 252, 1) 0%,
    rgba(51, 68, 222, 1) 100%
  );
}

.CompleteAccountTransfer .Modal .App-cta {
  display: block;
  text-align: center;
}

.DashboardV2 {
  padding-top: 3rem;
}

.DashboardV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
}

.DashboardV2-token-cards {
  margin-top: 0.5rem;
  margin-bottom: 70px;
}

.token-table-wrapper {
  border: 1px solid #000526;
  border-radius: 15px;
  background: #000526;
  padding: 0 1rem;
  text-align: left;
}

.token-table-wrapper .App-card-title {
  justify-content: unset;
  margin-top: -4px;
  padding: 10px;
  margin-top: 15px;
}

.token-table-wrapper .App-card-title img {
  margin-top: 3px;
  display: inline-flex;
  margin-left: 0.5rem;
}

.DashboardV2-projects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding: 2rem;
}


@media (max-width: 391px) {
  .DashboardV2-projects {
    display: contents;
 
  }
}



.App-card-title-icon {
  display: flex;
  margin-left: 12px;
}

.App-card-title-icon img + img {
  margin-left: 6px;
}

.stats-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  margin-bottom: 1rem;
}

.stats-wrapper .App-card {
  width: 100%;
  display: flex;
}

.stats-block {
  width: 100%;
}

.stats-piechart {
  margin: -10px;
  margin-left: 1rem;
}

.stats-block--equity .stats-label {
  grid-row-gap: 0;
}

.stats-label {
  font-size: 0.9rem;
  line-height: 1.032rem;
  border-radius: 4px;
  padding: 0.674rem;
  letter-spacing: 0.4px;
  text-align: left;
  z-index: 1;
  background: #080b27;
  cursor: pointer;
}

.stats-label-color {
  width: 4px;
  height: 100%;
  margin-right: 1rem;
}

.Available-network-group {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
}

.Available-network-group .Tooltip {
  display: flex;
}

.Available-network-group .Available-network {
  display: flex;
}

.Available-network-group .Available-network img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}

.Available-network-group .Tooltip-handle.active .Available-network img {
  -webkit-filter: unset;
          filter: unset;
}

.Available-network-group .Available-network img:hover {
  -webkit-filter: unset;
          filter: unset;
}

.token-table .token-symbol-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3rem;
}

.DashboardV2-token-cards .token-table td:nth-child(3),
.DashboardV2-token-cards .token-table th:nth-child(3) {
  width: 200px;
}

@media (max-width: 1024px) {
  .stats-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1rem;
  }

  .stats-wrapper .App-card {
    max-width: unset !important;
    margin: 0;
  }
}

@media (max-width: 1100px) {
  .token-table-wrapper {
    display: none;
  }
}

@media (max-width: 900px) {
  .DashboardV2-cards {
    grid-template-columns: 1fr;
  }

  .DashboardV2-projects {
    grid-template-columns: 1fr;
  }

  .DashboardV2-token-cards .token-grid {
    padding-top: 0;
  }
}

@media (max-width: 660px) {
  .stats-wrapper .App-card {
    flex-wrap: wrap;
    padding-bottom: 0;
  }

  .stats-piechart {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
  }
}
.cardborderradius{
  border-radius: 15px;
}
.mt10{
  margin-top: 10px;
}
.ahreftextcolor{
  color: white;
}
.ahreftextcolor:hover{
  color: white;
}

.Dashboard-title {
}

.Dashboard-volumes-header {
}

.Dashboard-volumes {
}

.Dashboard-fee-info {
  text-align: center;
  line-height: 1.5rem;
}

.Dashboard-token-card-bottom {
}

.Dashboard-title-primary {
}

.Dashboard-note {
  text-align: center;
}

.Dashboard-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 2rem;
  grid-gap: 1rem;
}

.Dashboard-fees-list {
}

.Dashboard-volume-list {
}

.Dashboard-token-card .Dashboard-token-title-options {
  opacity: 0.5;
  -webkit-filter: sepia(100%) hue-rotate(190deg);
          filter: sepia(100%) hue-rotate(190deg);
}

.Dashboard-token-card:hover .Dashboard-token-title-options {
  opacity: 1;
  -webkit-filter: none;
          filter: none;
}

.Dashboard-token-card.usde {
}

.Dashboard-token-title {
  display: grid;
  grid-template-columns: auto auto;
}

.Dashboard-token-title-options {
  text-align: right;
}

.Dashboard-token-title img {
  cursor: pointer;
  height: 1.2rem;
  margin-left: 0.3rem;
  opacity: 0.9;
}

.Dashboard-token-title img:hover {
  opacity: 1;
}

.Dashboard-token-info {
}

.Dashboard-fees {
  padding-bottom: 3rem;
}

@media (max-width: 700px) {
  .Dashboard-list {
    grid-template-columns: 1fr;
  }
}

.acolor{
    color: white;
}
.acolor:hover{
    color: rgb(59 130 245);
}
.DashboardV3-projects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    margin-top: 0.5rem;
    padding: 1.1rem;
}

@media (max-width: 768px) {
    .DashboardV3-projects {
        display: block;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        margin-top: 0.5rem;
        padding: 1.1rem;
    }
}
.FarmEquity .Page-title-section {
  position: relative;
  z-index: 2;
}

.FarmEquity{
  padding-top: 3rem;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.3rem;
}

.FarmEquity-address-input {
  padding: 1rem 2.2rem;
  padding-bottom: 0;
}

.FarmEquity-buy-equal-modal .Modal-content {
  max-width: 30rem;
}

.FarmEquity-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
}

.FarmEquity-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.2rem;
  border-radius: 2px;
  width: 1rem;
  height: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.FarmEquity-boost-icon {
  font-size: 0.7rem;
  z-index: 2;
}

.FarmEquity-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

.FarmEquity-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding-top: 1rem;
  margin-bottom:70px;
}

.FarmEquity-content {
}

@media (max-width: 900px) {
  .FarmEquity-cards {
    grid-template-columns: 1fr;
  }

  .FarmEquity-content {
    min-height: 100vh;
  }

  .FarmEquity-total-rewards-card {
    grid-row: 4;
  }
}
.ahreftextcolor{
  color: white;
}
.ahreftextcolor:hover{
  color: white;
}
.btnwt{
  width: 35%;
}
@media (max-width: 900px) {
  .btnwt{
    width: 38%;
  }
}
@media (max-width: 425px) {
  .btnwt{
    width: 46%;
  }
}
@media (max-width: 366px) {
  .btnwt{
    width: 53%;
  }
}
.mb-6{
  margin-bottom: 42px;
}
.marginbottommedia{
  margin-bottom: -20px;
}
@media (max-width: 767px) {
  .marginbottommedia{
    margin-bottom: 10px;
  }
}


 

.Home {
  min-height: 100vh;
  /* padding-bottom: calc(3rem + 200px); */
}

.default-container{
  padding:0px 16px;
}

.Home-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-top: 62px;
  position: relative;
  /* height: 52.581rem; */
  padding-bottom: 4.129rem;
  /* background: url("../../img/home-bg.png"); */
  /* background: url("../../img/Artwork_gmx.svg"); */
  
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
  margin-top: -62px;
}

.Home-title {
  font-size: 3.677rem;
  line-height: 4.387rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 0;
}

.Home-title-section-container {
  position: relative;
  z-index: 1;
  width: 100%;
  /* padding-top: 7.548rem; */
  padding-top: 4rem;
}

.Home-title-section {
  max-width: 33.677rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Home-description {
  font-size: 1.29rem;
  line-height: 1.613rem;
  max-width: 28.645rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.Home-description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-benefit-description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}


.Home-benefits-section {
  background: #000526;
  position: relative;
  z-index: 2;
  padding:0 16px;
}

.Home-benefits {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 3.6135rem;
  padding-bottom: 3.6135rem;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
}

.Home-benefit {
  padding: 1.5485rem 0;
  flex: 1 0 32%;
  min-width: 300px;
}

.Home-benefit-title {
  font-size: 1.419rem;
  line-height: 1.806rem;
  font-weight: normal;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
/*   margin-left: 1rem; */
}

.Home-benefit-description {
  font-size: 1.161rem;
  line-height: 1.484rem;
  max-width: 18.839rem;
  color: #fff;
  letter-spacing: 0;
}

.Home-benefit-icon {
  display: flex;
  align-items: center;
  padding: 0 0 1rem;
  font-size: 1.2rem;
}

.Home-cta-section {
/*   background: #101124; */
position: relative;
z-index: 2;
}

.Home-cta-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 5.161rem;
  padding-bottom: 5.161rem;
  align-items: center;
}

.Home-cta-info {
  max-width: 28rem;
  padding:0px 16px;
}

.Home-cta-info__title {
  font-size: 2.452rem;
  line-height: 3.097rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
}

.Home-cta-info__description {
  font-size: 1.161rem;
  line-height: 1.484rem;
  color: #fff;
  margin-bottom: 1.5rem;
  
}
.Home-cta-info__description p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-cta-options {
  max-width: 38rem;
  width: 100%;
}

.Home-cta-option-title {
  font-size: 1.548rem;
  line-height: 2.065rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  color: #000;
}

.Home-cta-option-subtitle {
  font-size: 1.161rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #c4b9a0;
  margin-bottom: 3px;
}

.home-tag-line{
  font-size: 24.5px;
    margin-top: 10px;
}

.Home-cta-button {
  display: block;
  padding: 1rem;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  margin-top: 2rem;
}

.Home-cta-option-icon {
  display: flex;
  align-items: center;
/*   width: 96px; */
  height: 96px;
  justify-content: center;
}

@media (min-width: 650px) {
  .Home-cta-option {
    max-width: 38rem;
    width: 100%;
    /* border: 1px solid #1e2136; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: flex;
    justify-content: center;
  }
  .Home-cta-option-info {
    text-align: right;
    display:flex;
    justify-content:center;
    flex-direction:column;
    align-items: center;
  }
  .Home-cta-option-action {
    display: flex;
    justify-content: flex-end;
    margin-top:25px;
  }
  .Home-cta-option-fantom {
  }
  
 }

 @media (max-width: 650px) {

  .Home-cta-option {
    max-width: 38rem;
    width: 100%;
    box-shadow: 0px 7px 12px #0000004d;
    /* border: 1px solid #1e2136; */
    padding: 1.032rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 15px;
    display: block;
    justify-content: space-between;
  }
  .imagescreem{
    width: 80%;
   
  }
  .Home-cta-option-info {
    text-align: center;
  }
  .Home-cta-option-action {
    display: flex;
    justify-content: center;
  }
  .Home-cta-option-fantom {

  }
  
 }



.Home-cta-option + .Home-cta-option {
  margin-top: 2.645rem;
}
.Home-token-card-section {
padding: 5rem 16px;
/*   background: #17182c; */
  margin-bottom: -3rem;
}

.Home-token-card-info {
text-align:center;
}

.Home-token-card-info__title {
  font-size: 2.452rem;
  line-height: 3.097rem;
  font-weight: bold;
  margin-bottom: 2rem;
  font-family:'Etna',sans-serif;
  letter-spacing: 1.5px;
}

.Home-token-card-options {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
}

.Home-token-card-option {
  position: relative;
  padding: 1.5rem;
  border-radius: 13px;
/*   max-width: 38.19rem; */
  /* background: #000526;
  box-shadow: 0px 5px 13px #00000085;
  border: 1px solid #000526;
  border-radius: 4px; */
  border: 1px solid rgb(28 36 75);
  border-radius: 0.75rem;
  background: rgb(17 20 58);
}

.Home-token-card-option-icon {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  letter-spacing: 0px;
  color: #ffffff;
  height: 2rem;
}

.Home-token-card-option-icon img {
  margin-right: 0.5rem;
}

.Home-token-card-option-title {
  font-size: 1.161rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.5rem;
}
.Home-token-card-option-title p{
  color: rgb(126 153 176/var(--tw-text-opacity))!important;
}

.Home-token-card-option-apr {
  font-size: 1rem;
  line-height: 1.484rem;
  letter-spacing: 0px;
  color: #fff;
  margin-top: 1.25rem;
}

.Home-token-card-option-action {
  margin-top: 1.25rem;
  display: flex;
  justify-content: space-between;
}

.Home-token-card-option-action > .buy a:first-child {
  margin-right: 1rem;
}

/* .Home-token-card-option-action .read-more {
  color: rgb(10 211 215) !important;
  border-color: rgba(10 211 215 0.6);
} */

/* .default-btnhome.read-more {
  color: rgb(10 211 215) !important;
  border-color: rgba(10, 211, 215, 0.6);
  background: none;
} */

.Home-latest-info-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-top: 4rem;
  padding-bottom: 1rem;
  width: 100%;
  flex-wrap: wrap;
  grid-gap:10px;
  gap:10px;
}

.Home-latest-info-block {
  /* background: #17182C 0% 0% no-repeat padding-box; */
  box-shadow: 0px 5px 22px #0000001f;
  box-shadow: 0px 12px 17px #00000024;
  box-shadow: 0px 7px 8px #00000033;
  /* mix-blend-mode: multiply; */
  border: 1px solid #ffffff14;
  border-radius: 4px;
  max-width: 24.526rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 1.806rem;
  padding-bottom: 1.806rem;
  margin: 1.032rem 0;
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  flex: 1 0 33%;
  margin: 1em 0;
  min-width: 300px;
}

.Home-latest-info__icon {
  margin-left: 1.645rem;
  margin-right: 1.048rem;
}

.Home-latest-info__title {
  color: #fff;
  font-size: 1.032rem;
  line-height: 1.613rem;
  letter-spacing: 0px;
}

.Home-latest-info__value {
  color: white;
  font-size: 1.936rem;
  line-height: 2.516rem;
  letter-spacing: 0px;
  font-weight:bold;
}

@media (max-width: 400px) {
 .Home-latest-info__value {
  color: white;
  font-size: 1.336rem;
  }
}

.Home-latest-info__description {
  letter-spacing: 0px;
  color: #c4b9a0;
  font-size: 0.903rem;
  line-height: 1.613rem;
}

.Home-video-section {
  background: #101124;
  padding: 5.161rem 0;
}

.Home-video-block {
  background: #0b131c 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 20px #0000003d;
  border-radius: 7px;
  max-width: 57.677rem;
  max-height: 32.645rem;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}

.Home-faqs-section {
  background: #101124;
  padding: 5.161rem 0;
}

.Home-faqs-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Home-faqs-documentation {
  margin-bottom: 3.163rem;
}

.Home-faqs-introduction__title {
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 2.452rem;
  line-height: 3.097rem;
}

.Home-faqs-introduction__description {
  letter-spacing: 0px;
  color: #c4b9a0;
  font-size: 1.161rem;
  line-height: 1.484rem;
  margin-bottom: 2.387rem;
  margin-top: 1rem;
  max-width: 19.226rem;
}

.Home-faqs-content-block {
  max-width: 52rem;
  width: 100%;
}

.Home-faqs-content {
  background: #000526;
  border: 1px solid #000526;
  border-radius: 4px;
  cursor: pointer;
  padding: 2.387rem 3.226rem;
  width: 100%;
  max-width: 52rem;
}

.Home-faqs-content:hover {
  background: #433c0055;
}

.Home-faqs-content + .Home-faqs-content {
  margin-top: 1rem;
}

.Home-faqs-content-header {
  display: flex;
}

.Home-faqs-content-header__icon {
  display: flex;
  align-items: center;
  margin-right: 1.139rem;
  font-size: 0.89rem;
  color: #000526;
}

.Home-faqs-content-header__text {
  font-size: 1.097rem;
  line-height: 1.419rem;
  letter-spacing: 0px;
  color: #ffffff;
}

.Home-faqs-content-main {
  overflow: hidden;
  height: 0px;
  visibility: hidden;
  padding: 0 0rem 0 2rem;
  letter-spacing: 0px;
  color: #c4b9a0;
  transition: all 0.3s ease-in-out 0s;
}

.Home-faqs-content-main.opened {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  visibility: visible;
  padding: 1rem 0rem 0 2rem;
  transition: all 0.3s ease-in-out 0s;
}

@media (max-width: 1000px) {
  .Home-token-card-option-action {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
  .Home-token-card-option-action a {
    justify-content: center;
    display: inline-block;
  }
  .Home-token-card-option-action a {
    text-align: center;
  }
  .Home-token-card-option-action > .buy {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
  .Home-token-card-option-action > .read-more {
    display: inline-block;
  }
  .Home-token-card-option-action > .buy a:first-child {
    margin-right: 0;
  }
}

@media (max-width: 750px) {
  .Home-token-card-options {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;
  }
}

@media (max-width: 500px) {
  .Home-title-section-container {
    padding-top: 4.774rem;
  }

  .Home-title {
    font-size: 2.8rem;
    line-height: 3.6rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
    letter-spacing: 0;
  }

  .Home-cta-options {
    width: calc(100% + 32px);
    margin-left: -16px;
    margin-right: -16px;
  }

 /*  .Home-cta-option-info {
    width: 50%;
  } */

  .Home-cta-option-subtitle {
    margin-bottom: 4px;
  }

  .Home-cta-option-title {
    line-height: 1.548rem;
  }

  .Home-cta-option-action {
    margin-top: 0.5rem;
  }

  .Home-latest-info-container {
    padding-left: 16px !important;
    padding-right: 16px !important;
    padding-top: 4.774rem;
  }

  .Home-latest-info__icon {
    margin-right: 1.903rem;
  }

  .Home-latest-info-block + .Home-latest-info-block {
    margin-top: 16px;
  }

  .Home-faqs-content {
    padding-right: 1rem;
  }
}

@media (max-width: 400px) {
  .Home-description {
    font-size: 1rem;
    line-height: 1.4rem;
    max-width: 28.645rem;
    color: #fff;
    margin-bottom: 1.677rem;
  }
  /*
  .Home-token-card-option-action {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }

  .Home-token-card-option-action .default-btn {
    margin: 0.5rem;
  } */
}

@media (max-width: 350px) {
  .Home-title {
    font-size: 2.5rem;
    line-height: 3rem;
    font-weight: bold;
    margin-bottom: 1rem;
    letter-spacing: 0;
  }

  .Home-latest-info__icon {
    margin: 0 1rem;
  }
}

.iconsize{
  width: 100%;
}
@media (max-width: 400px) {
  .iconsize{
  width: 80%;
  }
}
@media (max-width: 580px) {
  .iconsize{
  width: 80%;
  }
}
.titletextcolor{
  color: rgb(59 130 245);
}
.borderradius{
  border-radius: 15px;
}

.homepage-container-pd{
  padding-left: 16px!important;
  padding-right: 16px!important;
}

  
/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

:root {
  --side: #1969ff;
  --side-dark: #1a1a1b;
  --coin-size: 250px;
  --coin-face: url('https://s3.coinmarketcap.com/static/img/portraits/62d51d9af192d82df8ff3a83.png');
}


.fantomCoin {
  height: 250px;
  height: var(--coin-size);
  width: 250px;
  width: var(--coin-size);
  margin: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fantomCoin::before {
  content: '';
  display: block;
  position: relative;
  height: 250px;
  height: var(--coin-size);
  width: 250px;
  width: var(--coin-size);
  border-radius: 50%;
  background-color: var(--face);
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
  background-image: url('https://s3.coinmarketcap.com/static/img/portraits/62d51d9af192d82df8ff3a83.png');
  background-image: var(--coin-face);
  background-size: 100% 100%;
  background-position: center;
  background-blend-mode: overlay;
}

/* animation definitions */

@-webkit-keyframes spin {
  0% {
    width: 250px;
    width: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    box-shadow:
      0.05rem 0 0 #1969ff,
      0.1rem 0 0 #1969ff,
      0.15rem 0 0 #1969ff,
      0.2rem 0 0 #1969ff,
      0.25rem 0 0 #1969ff,
      0.3rem 0 0 #1969ff,
      0.35rem 0 0 #1969ff,
      0.4rem 0 0 #1969ff,
      0.45rem 0 0 #1969ff,
      0.5rem 0 0 #1969ff,
      0.55rem 0 0 #1969ff,
      0.6rem 0 0 #1969ff,
      0.65rem 0 0 #1969ff,
      0.7rem 0 0 #1969ff,
      0.75rem 0 0 #1969ff;
            box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    -webkit-transform: translateX(-0.375rem);
            transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    box-shadow:
      -0.05rem 0 0 #1969ff,
      -0.1rem 0 0 #1969ff,
      -0.15rem 0 0 #1969ff,
      -0.2rem 0 0 #1969ff,
      -0.25rem 0 0 #1969ff,
      -0.3rem 0 0 #1969ff,
      -0.35rem 0 0 #1969ff,
      -0.4rem 0 0 #1969ff,
      -0.45rem 0 0 #1969ff,
      -0.5rem 0 0 #1969ff,
      -0.55rem 0 0 #1969ff,
      -0.6rem 0 0 #1969ff,
      -0.65rem 0 0 #1969ff,
      -0.7rem 0 0 #1969ff,
      -0.75rem 0 0 #1969ff;
            box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    -webkit-transform: translateX(0.375rem);
            transform: translateX(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    width: 250px;
    width: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes spin {
  0% {
    width: 250px;
    width: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    width: 0.1rem;
    box-shadow:
      0.05rem 0 0 #1969ff,
      0.1rem 0 0 #1969ff,
      0.15rem 0 0 #1969ff,
      0.2rem 0 0 #1969ff,
      0.25rem 0 0 #1969ff,
      0.3rem 0 0 #1969ff,
      0.35rem 0 0 #1969ff,
      0.4rem 0 0 #1969ff,
      0.45rem 0 0 #1969ff,
      0.5rem 0 0 #1969ff,
      0.55rem 0 0 #1969ff,
      0.6rem 0 0 #1969ff,
      0.65rem 0 0 #1969ff,
      0.7rem 0 0 #1969ff,
      0.75rem 0 0 #1969ff;
            box-shadow:
      0.05rem 0 0 var(--side),
      0.1rem 0 0 var(--side),
      0.15rem 0 0 var(--side),
      0.2rem 0 0 var(--side),
      0.25rem 0 0 var(--side),
      0.3rem 0 0 var(--side),
      0.35rem 0 0 var(--side),
      0.4rem 0 0 var(--side),
      0.45rem 0 0 var(--side),
      0.5rem 0 0 var(--side),
      0.55rem 0 0 var(--side),
      0.6rem 0 0 var(--side),
      0.65rem 0 0 var(--side),
      0.7rem 0 0 var(--side),
      0.75rem 0 0 var(--side);
    -webkit-transform: translateX(-0.375rem);
            transform: translateX(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    width: 0.1rem;
    box-shadow:
      -0.05rem 0 0 #1969ff,
      -0.1rem 0 0 #1969ff,
      -0.15rem 0 0 #1969ff,
      -0.2rem 0 0 #1969ff,
      -0.25rem 0 0 #1969ff,
      -0.3rem 0 0 #1969ff,
      -0.35rem 0 0 #1969ff,
      -0.4rem 0 0 #1969ff,
      -0.45rem 0 0 #1969ff,
      -0.5rem 0 0 #1969ff,
      -0.55rem 0 0 #1969ff,
      -0.6rem 0 0 #1969ff,
      -0.65rem 0 0 #1969ff,
      -0.7rem 0 0 #1969ff,
      -0.75rem 0 0 #1969ff;
            box-shadow:
      -0.05rem 0 0 var(--side),
      -0.1rem 0 0 var(--side),
      -0.15rem 0 0 var(--side),
      -0.2rem 0 0 var(--side),
      -0.25rem 0 0 var(--side),
      -0.3rem 0 0 var(--side),
      -0.35rem 0 0 var(--side),
      -0.4rem 0 0 var(--side),
      -0.45rem 0 0 var(--side),
      -0.5rem 0 0 var(--side),
      -0.55rem 0 0 var(--side),
      -0.6rem 0 0 var(--side),
      -0.65rem 0 0 var(--side),
      -0.7rem 0 0 var(--side),
      -0.75rem 0 0 var(--side);
    -webkit-transform: translateX(0.375rem);
            transform: translateX(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    width: 250px;
    width: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@-webkit-keyframes flip {
  0% {
    height: 250px;
    height: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    box-shadow:
      0 0.05rem 0 #1969ff,
      0 0.1rem 0 #1969ff,
      0 0.15rem 0 #1969ff,
      0 0.2rem 0 #1969ff,
      0 0.25rem 0 #1969ff,
      0 0.3rem 0 #1969ff,
      0 0.35rem 0 #1969ff,
      0 0.4rem 0 #1969ff,
      0 0.45rem 0 #1969ff,
      0 0.5rem 0 #1969ff,
      0 0.55rem 0 #1969ff,
      0 0.6rem 0 #1969ff,
      0 0.65rem 0 #1969ff,
      0 0.7rem 0 #1969ff,
      0 0.75rem 0 #1969ff;
            box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    -webkit-transform: translateY(-0.375rem);
            transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    box-shadow:
      0 -0.05rem 0 #1969ff,
      0 -0.1rem 0 #1969ff,
      0 -0.15rem 0 #1969ff,
      0 -0.2rem 0 #1969ff,
      0 -0.25rem 0 #1969ff,
      0 -0.3rem 0 #1969ff,
      0 -0.35rem 0 #1969ff,
      0 -0.4rem 0 #1969ff,
      0 -0.45rem 0 #1969ff,
      0 -0.5rem 0 #1969ff,
      0 -0.55rem 0 #1969ff,
      0 -0.6rem 0 #1969ff,
      0 -0.65rem 0 #1969ff,
      0 -0.7rem 0 #1969ff,
      0 -0.75rem 0 #1969ff;
            box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    -webkit-transform: translateY(0.375rem);
            transform: translateY(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    height: 250px;
    height: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@keyframes flip {
  0% {
    height: 250px;
    height: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  
  49.999% {
    height: 0.1rem;
    box-shadow:
      0 0.05rem 0 #1969ff,
      0 0.1rem 0 #1969ff,
      0 0.15rem 0 #1969ff,
      0 0.2rem 0 #1969ff,
      0 0.25rem 0 #1969ff,
      0 0.3rem 0 #1969ff,
      0 0.35rem 0 #1969ff,
      0 0.4rem 0 #1969ff,
      0 0.45rem 0 #1969ff,
      0 0.5rem 0 #1969ff,
      0 0.55rem 0 #1969ff,
      0 0.6rem 0 #1969ff,
      0 0.65rem 0 #1969ff,
      0 0.7rem 0 #1969ff,
      0 0.75rem 0 #1969ff;
            box-shadow:
      0 0.05rem 0 var(--side),
      0 0.1rem 0 var(--side),
      0 0.15rem 0 var(--side),
      0 0.2rem 0 var(--side),
      0 0.25rem 0 var(--side),
      0 0.3rem 0 var(--side),
      0 0.35rem 0 var(--side),
      0 0.4rem 0 var(--side),
      0 0.45rem 0 var(--side),
      0 0.5rem 0 var(--side),
      0 0.55rem 0 var(--side),
      0 0.6rem 0 var(--side),
      0 0.65rem 0 var(--side),
      0 0.7rem 0 var(--side),
      0 0.75rem 0 var(--side);
    -webkit-transform: translateY(-0.375rem);
            transform: translateY(-0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: linear;
            animation-timing-function: linear;
  }
  
  50.001% {
    height: 0.1rem;
    box-shadow:
      0 -0.05rem 0 #1969ff,
      0 -0.1rem 0 #1969ff,
      0 -0.15rem 0 #1969ff,
      0 -0.2rem 0 #1969ff,
      0 -0.25rem 0 #1969ff,
      0 -0.3rem 0 #1969ff,
      0 -0.35rem 0 #1969ff,
      0 -0.4rem 0 #1969ff,
      0 -0.45rem 0 #1969ff,
      0 -0.5rem 0 #1969ff,
      0 -0.55rem 0 #1969ff,
      0 -0.6rem 0 #1969ff,
      0 -0.65rem 0 #1969ff,
      0 -0.7rem 0 #1969ff,
      0 -0.75rem 0 #1969ff;
            box-shadow:
      0 -0.05rem 0 var(--side),
      0 -0.1rem 0 var(--side),
      0 -0.15rem 0 var(--side),
      0 -0.2rem 0 var(--side),
      0 -0.25rem 0 var(--side),
      0 -0.3rem 0 var(--side),
      0 -0.35rem 0 var(--side),
      0 -0.4rem 0 var(--side),
      0 -0.45rem 0 var(--side),
      0 -0.5rem 0 var(--side),
      0 -0.55rem 0 var(--side),
      0 -0.6rem 0 var(--side),
      0 -0.65rem 0 var(--side),
      0 -0.7rem 0 var(--side),
      0 -0.75rem 0 var(--side);
    -webkit-transform: translateY(0.375rem);
            transform: translateY(0.375rem);
    background-color: var(--lowlight);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  
  100% {
    height: 250px;
    height: var(--coin-size);
    box-shadow:
      0 0 0 #1a1a1b;
            box-shadow:
      0 0 0 var(--side-dark);
  }
}

@media (max-width: 1000px) {
  .Home-cta-option-info {
    display: none;
  }
  .Home-cta-container{
    justify-content: center;
  }
  .Home-cta-info{
    background: rgb(17 20 58);
    border: 1px solid rgb(28 36 75);
    padding: 25px;
    border-radius: 15px;
    width: 100%;
    max-width:calc(100% - 16px);
  }
  .Home-cta-info__description{
    margin-bottom:25px;
  }
  .Home-cta-info__title{
    margin-bottom:25px;
  }
}
.NftWallet input {
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.NftWallet label {
  display: block;
  margin-bottom: 0.5rem;
}

.NftWallet-content {
  padding: 3rem;
  padding-top: 1rem;
  max-width: 25rem;
}

.NftWallet-row {
  margin-bottom: 1rem;
}

.NftWallet button {
  margin-top: 0.5rem;
}

.Orders-overview {
  font-size: 0.9rem;
  margin: 1rem 2rem;
}

.Orders-overview-stats {
  line-height: 1.5;
}

.Orders-overview-table {
  border-collapse: collapse;
}

.Orders-overview th {
  opacity: 0.5;
  font-weight: normal;
  text-align: left;
  padding: 0.5rem 1rem 0.5rem 0;
}

.Orders-overview td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem 0.5rem 0;
}

.Orders-overview .near {
  color: orange;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}

.Orders-overview-action {
  background: none;
  border: none;
  text-decoration: underline;
  color: #fff;
}
.Positions-overview {
  font-size: 0.9rem;
  margin: 1rem 2rem;
}

.Positions-overview-stats {
  line-height: 1.5;
}

.Positions-overview-table {
  border-collapse: collapse;
}

.Positions-overview th {
  opacity: 0.5;
  font-weight: normal;
  text-align: left;
  padding: 0.5rem 1rem 0.5rem 0;
}

.Positions-overview td {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 0.5rem 1rem 0.5rem 0;
}

.Positions-overview .near {
  color: orange;
}
.Positions-overview .near.negative {
  color: #fa3c58;
}

.SellEquity-content {
  display: grid;
  grid-template-columns: 1fr 28rem;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding: 2rem;
  padding-top: 1rem;
}

.SellEquity-box {
  padding: 1rem;
}

.SellEquity-cta {
  margin-top: 0.5rem;
}


@media (max-width: 900px) {
  .SellEquity-content {
    grid-template-columns: 1fr;
  }
}

.StakeV2 .Page-title-section {
  position: relative;
  z-index: 2;
}

.VesterDepositModal-info-rows {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu {
  margin-bottom: 0.5rem;
}

.CompoundModal-menu .Checkbox {
  margin-bottom: 0.3rem;
}

.StakeV2-address-input {
  padding: 1rem 2.2rem;
  padding-bottom: 0;
}

.StakeV2-buy-equal-modal .Modal-content {
  max-width: 30rem;
}

.StakeV2-address-input input {
  box-sizing: border-box;
  width: 100%;
  font-size: 1.1rem;
}

.StakeV2-boost-bar {
  overflow: hidden;
  vertical-align: middle;
  margin-left: 0.2rem;
  border-radius: 2px;
  width: 1rem;
  height: 0.5rem;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: inline-block;
  position: relative;
}

.StakeV2-boost-icon {
  font-size: 0.7rem;
  z-index: 2;
}

.StakeV2-boost-bar-fill {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
}

.StakeV2-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  margin-top: 0.5rem;
  padding: 1rem;
  padding-top: 1rem;
}

.StakeV2-content {
}

@media (max-width: 900px) {
  .StakeV2-cards {
    grid-template-columns: 1fr;
  }

  .StakeV2-content {
    min-height: 100vh;
  }

  .StakeV2-total-rewards-card {
    grid-row: 4;
  }
}
.ahreftextcolor{
  color: white;
}
.ahreftextcolor:hover{
  color: white;
}
.btnwt{
  width: 35%;
}
@media (max-width: 900px) {
  .btnwt{
    width: 38%;
  }
}
@media (max-width: 425px) {
  .btnwt{
    width: 46%;
  }
}
@media (max-width: 366px) {
  .btnwt{
    width: 53%;
  }
}
.mb-6{
  margin-bottom: 42px;
}
.marginbottommedia{
  margin-bottom: -20px;
}
@media (max-width: 767px) {
  .marginbottommedia{
    margin-bottom: 10px;
  }
}


 

.Stake {
}

.Stake-note {
  text-align: center;
  margin-bottom: 0.3rem;
}

.Stake-cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2rem;
  grid-gap: 1rem;
  margin-top: 0.5rem;
}

.Stake-info {
}

.StakeModal .Modal-content {
  width: 20rem;
}

.Stake-warning {
  text-align: center;
  margin-top: 0.3rem;
}

@media (max-width: 800px) {
  .StakeModal .Modal-content {
    width: auto;
  }
  .Stake-cards {
    grid-template-columns: 1fr;
  }
}

